import appClient from '@shared/api/appClient';
import appLibrary from '@shared/base/appLibrary';
import { setChannel } from '@course-portal/services/notifications';
import * as Sentry from '@sentry/vue';

export default {
  namespaced: true,
  namespace: 'user',
  state: {
    guest: null,
    user: null,
    avatarCrop: null,
    avatarPreview: null,
    avatarInput: null,
    channel: null,
    deleteAccount: false,
  },
  getters: {
    avatarImage: (state) => {
      return state.user && state.user.avatar
        ? state.avatarPreview
        : appLibrary.methods.vaporAsset('images/avatar-placeholder.png');
    },
    hasFriendReferrals: (state) => {
      return state.user && state.user.has_friend_referrals;
    },
  },
  actions: {
    getUser({ commit }) {
      return new Promise((resolve) => {
        appClient.getUser().then((response) => {
          if (response) {
            // Preload avatar
            const image = new Image();
            image.src = data.avatar;

            commit('GET_USER', response.data);

            // Set Laravel Echo channel
            setChannel(response.data.id);

            // Set Sentry User
            Sentry.setUser({
              id: response.data.id,
              email: response.data.email,
            });

            resolve();
          }
        });
      });
    },
    updateUser({ commit }, value) {
      return new Promise((resolve, reject) => {
        appClient
          .updateUser(value)
          .then(() => {
            commit('UPDATE_USER', value);
            resolve();
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    deleteUser() {
      appClient.deleteUser().then(() => {
        window.location.href = `/login?account_deleted`;
      });
    },
    updatePassword({}, value) {
      return new Promise((resolve, reject) => {
        appClient.updateUser(value).then((response) => {
          if (response && response.status === 422) {
            reject(response);
          } else {
            resolve();
          }
        });
      });
    },
    cropAvatar({ commit }, value) {
      commit('CROP_AVATAR', value);
    },
    updateAvatar({ commit }, value) {
      commit('UPDATE_AVATAR', value);
    },
    removeAvatar({ commit }) {
      commit('REMOVE_AVATAR');
    },
    setAvatarInput({ commit }, value) {
      commit('SET_AVATAR_INPUT', value);
    },
    setGuest({ commit }, value) {
      if (value) {
        commit('SET_GUEST', value);
      }
    },
    toggleDeleteAccount({ commit }, value) {
      commit('TOGGLE_DELETE_ACCOUNT', value);
    },
  },
  mutations: {
    GET_USER(state, value) {
      state.user = value;
      state.avatarPreview = value.avatar;
    },
    SET_CHANNEL(state, value) {
      state.channel = window.Echo.private(value);
    },
    UPDATE_USER(state, value) {
      state.user.first_name = value.get('first_name');
      state.user.last_name = value.get('last_name');
    },
    CROP_AVATAR(state, value) {
      state.avatarCrop = value;
    },
    UPDATE_AVATAR(state, value) {
      state.avatarPreview = value.preview ? value.preview : state.avatarPreview;
      state.user.avatar = value.file ? value.file : state.user.avatar;
    },
    REMOVE_AVATAR(state) {
      state.avatarPreview = '';
      state.user.avatar = '';
    },
    SET_AVATAR_INPUT(state, value) {
      state.avatarInput = value;
    },
    SET_GUEST(state, value) {
      state.guest = value;
    },
    TOGGLE_DELETE_ACCOUNT(state, value) {
      state.deleteAccount = value;
    },
  },
};
