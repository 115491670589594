import { createWebHistory, createRouter } from 'vue-router';
import Home from './pages/home/index.vue';
import QuickTips from './pages/quick-tips/index.vue';
import QuickTipsAll from './pages/quick-tips/QuickTipsAll.vue';
import Profile from './pages/profile/index.vue';
import ProfileEdit from './pages/profile/ProfileEdit.vue';
import ProfilePublic from './pages/profile/ProfilePublic.vue';
import Lesson from './pages/lesson/index.vue';
import Course from './pages/course/index.vue';
import Refer from './pages/refer/index.vue';
import PageNotFound from './components/layout/AppPageNotFound.vue';
import TrialEnded from './pages/course/TrialEnded.vue';
import AppNotifications from './pages/notifications/AppNotifications.vue';
import NProgress from 'nprogress';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/quick-tips/:id?',
      name: 'quick-tips',
      component: QuickTips,
    },
    {
      path: '/quick-tips/all',
      name: 'quick-tips-all',
      component: QuickTipsAll,
    },
    {
      path: '/course/:id',
      name: 'course',
      component: Course,
    },
    {
      path: '/lesson/:id',
      name: 'lesson',
      component: Lesson,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/profile-edit',
      name: 'profile-edit',
      component: ProfileEdit,
    },
    {
      path: '/user/:id',
      name: 'user',
      component: ProfilePublic,
    },
    {
      path: '/refer-a-friend',
      name: 'refer',
      component: Refer,
    },
    {
      path: '/share/:id',
      name: 'share',
      component: Lesson,
    },
    {
      path: '/ica-trial-ended',
      name: 'trial-ended',
      component: TrialEnded,
    },
    {
      path: '/notifications/:step',
      name: 'notifications',
      component: AppNotifications,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'page-not-found',
      component: PageNotFound,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach((to, from) => {
  window.ipse.dispatch({
    action: 'page_viewed',
    data_event: {
      from: from?.fullPath,
      to: to?.fullPath,
    },
  });
});

export default router;
