<template>
  <app-container class="quick-tips-all-ph__header">
    <div class="quick-tips-all-ph__categories">
      <div class="quick-tips-all-ph__option" v-for="(option, index) in 7" :key="index"></div>
    </div>
  </app-container>
</template>

<script>
import AppContainer from '@course-portal/components/layout/AppContainer.vue';
export default {
  components: {
    AppContainer,
  },
};
</script>

<style lang="scss">
.quick-tips-all-ph {
  &__header {
    padding: 0;
  }

  &__categories {
    display: flex;
    position: relative;
    overflow: scroll;
    gap: 8px;
    padding: 0 10px 16px 10px;
    margin-top: 26px;
    @include hide-scrollbar;

    // Targets iOS Safari
    @supports (-webkit-touch-callout: none) {
      // Smooth horizontal options scrolling on iOS devices
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
    }

    @include tablet-portrait {
      padding: 0 0 16px 0;
    }
  }

  &__option {
    position: relative;
    min-width: 100px;
    border-radius: 32px;
    height: 32px;
    background: var(--gray-8);
    animation: shimmer 1s ease-in-out infinite;
    animation-direction: alternate;

    &:first-child {
      min-width: 48px;
    }
  }
}
</style>
