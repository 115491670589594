<template>
  <a class="files-item" :href="file.url" target="_blank" @click="trackFileClick">
    <div class="files-item__thumbnail" :class="{ 'files-item__thumbnail--loading': !loaded }" v-if="hasThumbnail">
      <img :src="thumbnail" :alt="file.title" class="files-item__thumbnail-image" @load="loaded = true" />
    </div>

    <div class="files-item__file-icon" v-else-if="isImage">
      <font-awesome-icon :icon="['fas', 'file-image']" />
    </div>

    <div class="files-item__file-icon" v-else-if="isVideo">
      <font-awesome-icon :icon="['fas', 'file-video']" />
    </div>

    <div class="files-item__file-icon" v-else>
      <font-awesome-icon :icon="['fas', 'file-lines']" />
    </div>

    <div class="files-item__name">{{ file.title }}</div>

    <div class="files-item__icon">
      <font-awesome-icon :icon="['fas', 'chevron-right']" />
    </div>
  </a>
</template>

<script>
export default {
  name: 'LessonFilesItem',
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    hasThumbnail() {
      return !!this.file.thumbnail;
    },
    isImage() {
      return ['jpg', 'jpeg', 'png', 'heic', 'dng'].includes(this.file.type);
    },
    isVideo() {
      return ['mov'].includes(this.file.type);
    },
    thumbnail() {
      return `https://images.iphonephotographyschool.com/${this.file.thumbnail}/${encodeURIComponent(this.file.title)}.jpg?width=170`;
    },
  },
  methods: {
    trackFileClick() {
      this.trackEvent('button_clicked', {
        button: 'lesson_view_open_file',
        file_name: this.file.title,
        file_id: this.file.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.files-item {
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  margin: 0 0 10px 0;
  border-radius: 8px;
  background: var(--gray-7);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:last-child {
    margin: 0;
  }
  &:hover {
    background: var(--gray-6);
  }

  @include tablet-portrait {
    padding: 12px 24px 12px 15px;
  }

  &__thumbnail {
    position: relative;
    width: 85px;
    height: 56px;
    margin: 0 16px 0 0;
    border-radius: 5px;
    overflow: hidden;

    &--loading {
      background: var(--gray-6);
      animation: shimmer 1s ease-in-out infinite;
      animation-direction: alternate;

      img {
        display: none;
      }
    }
  }

  &__thumbnail-image {
    position: absolute;
    top: 50%;
    left: 0;
    object-fit: cover;
    transform: translateY(-50%);
  }

  &__file-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85px;
    height: 56px;
    font-size: 40px;
    margin: 0 16px 0 0;
    border-radius: 5px;
    background: var(--gray-8);
    color: var(--gray-1);
  }

  &__name {
    font-size: $text-sm;
    margin-right: 24px;

    @include tablet-landscape {
      font-size: $text-md;
    }
    @include ipad-pro-129(portrait) {
      font-size: $text-sm;
    }
  }

  &__icon {
    margin-left: auto;
    font-size: 20px;

    @include tablet-portrait {
      font-size: 18px;
    }
  }
}
</style>
