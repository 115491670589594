<template>
  <div class="home-card" @click="cardAction">
    <img :src="coverSquare" alt="" class="home-card__cover" />
    <div class="home-card__info">
      <h3 class="home-card__title">{{ card.title }}</h3>
      <h4 class="home-card__author">{{ card.author }}</h4>
      <app-progress :progress="card.percent_completed || 0"></app-progress>
    </div>
    <div class="home-card__play">
      <font-awesome-icon :icon="['fas', 'play']" />
    </div>
  </div>
</template>

<script>
// CONSTANTS
const squareSize = 454;

export default {
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cardLink() {
      return this.isMobile ? this.card.id : this.card.current_lesson;
    },
    coverSquareSize() {
      return Math.ceil(this.pixelRatio * squareSize);
    },
    coverSquare() {
      return `https://images.iphonephotographyschool.com/${this.card.cover_square}/${this.coverSquareSize}/${this.card.product_slug}.jpg`;
    },
  },
  methods: {
    cardAction() {
      this.trackEvent('button_clicked', { button: 'courses_view_course_selected' });
      this.$router.push({ name: this.isMobile ? 'course' : 'lesson', params: { id: this.cardLink } });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-card {
  position: relative;
  border: 1px solid #464646;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
  }

  &__cover {
    width: 100%;
    aspect-ratio: 73/61;
    object-fit: cover;
  }

  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 16px 16px 16px;
  }

  &__title {
    font-size: $header-xs;
    margin: 0;
  }

  &__author {
    font-weight: normal;
    color: var(--gray-2);
    margin: 4px 0 0 0;
  }

  &__play {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #000;
    transform: translate(-50%, -50%);
    box-shadow: 0px 3.564px 6.237px 0px rgba(0, 0, 0, 0.25);
    z-index: 2;
  }

  .progress-bar {
    position: relative;
    height: 3px;
    margin: 10px 0 0 0;
    background: #5b5a5a;
  }
  ::v-deep(.progress-bar__progress) {
    background: #ffb55e;
  }
}
</style>
