<template>
  <div class="home-slider">
    <Splide :options="config" :has-track="false">
      <div class="splide__arrows">
        <button class="splide__arrow splide__arrow--prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="30" viewBox="0 0 14 30" fill="none">
            <path d="M1 0.75L13 14.75L1 29.25" stroke="#8E8E93" />
          </svg>
        </button>
        <button class="splide__arrow splide__arrow--next">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="30" viewBox="0 0 14 30" fill="none">
            <path d="M1 0.75L13 14.75L1 29.25" stroke="#8E8E93" />
          </svg>
        </button>
      </div>

      <SplideTrack>
        <SplideSlide v-for="card in ownedCourses" :key="card.id">
          <home-card-owned :card="card" />
        </SplideSlide>
      </SplideTrack>
    </Splide>
  </div>
</template>

<script>
import HomeCardOwned from './HomeCardOwned.vue';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/vue-splide';
import { mapGetters } from 'vuex';

export default {
  name: 'HomeIndex',
  components: {
    HomeCardOwned,
    Splide,
    SplideTrack,
    SplideSlide,
  },
  data() {
    return {
      isStart: true,
      isEnd: false,
    };
  },
  computed: {
    ...mapGetters('courses', ['ownedCourses', 'lockedCourses']),
    config() {
      return {
        gap: this.isPhone ? 8 : 32,
        perPage: this.isMobile ? 1 : 3,
        pagination: false,
        arrows: this.isMobile ? false : true,
        omitEnd: true,
        focus: 0,
      };
    },
  },
  methods: {
    onMove(e, newIndex, prevIndex, destIndex) {
      const end = e.Components.Controller.getEnd();
      const start = 0;

      if (destIndex === end) {
        this.isEnd = true;
      } else if (destIndex === start) {
        this.isStart = true;
      } else {
        this.isEnd = false;
        this.isStart = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-slider {
  position: relative;

  ::v-deep(.splide__slide) {
    max-width: 80%;

    @include phone-landscape {
      max-width: 40%;
    }
    @include tablet-portrait(portrait) {
      max-width: calc(40%);
    }
    @media (hover: none) and (pointer: coarse) and (min-width: 1024px) {
      max-width: calc(40%);
    }
    @media (hover: none) and (pointer: fine) and (min-width: 1024px) {
      max-width: none;
    }
  }

  ::v-deep(.splide__track) {
    width: calc(100% + 10px);
    padding-right: 10px !important;

    @include phone-landscape {
      width: auto;
      padding-right: 0 !important;
    }
  }
  ::v-deep(.splide__arrows) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
  }
  ::v-deep(.splide__arrow--prev),
  ::v-deep(.splide__arrow--next) {
    width: 48px;
    height: 100%;
    padding: 0;
    border: none;
    outline: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  ::v-deep(.splide__arrow--prev[disabled]),
  ::v-deep(.splide__arrow--next[disabled]) {
    opacity: 0;
    visibility: hidden;
  }
  ::v-deep(.splide__arrow--prev) {
    transform: translateX(-100%) rotate(180deg);
  }
  ::v-deep(.splide__arrow--next) {
    margin-left: auto;
    transform: translateX(100%);
  }
}
</style>
