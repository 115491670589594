<template>
  <div class="profile-edit-ph">
    <div class="profile-edit-ph__block">
      <div class="profile-edit-ph__item profile-edit-ph__avatar"></div>
      <div class="profile-edit-ph__item profile-edit-ph__avatar-remove"></div>

      <div class="profile-edit-ph__fields">
        <div class="profile-edit-ph__item profile-edit-ph__field profile-edit-ph__field--half"></div>
        <div class="profile-edit-ph__item profile-edit-ph__field profile-edit-ph__field--half"></div>
        <div class="profile-edit-ph__item profile-edit-ph__field"></div>
      </div>

      <div class="profile-edit-ph__item profile-edit-ph__field-note-line-1"></div>
      <div class="profile-edit-ph__item profile-edit-ph__field-note-line-2"></div>

      <div class="profile-edit-ph__item profile-edit-ph__save-profile"></div>
    </div>

    <div class="profile-edit-ph__block" v-if="isAppMode">
      <h2 class="profile-edit-ph__block-title">{{ $t('profile.notifications') }}</h2>

      <div class="profile-edit-ph__fields">
        <div class="profile-edit-ph__item profile-edit-ph__field"></div>
        <div class="profile-edit-ph__item profile-edit-ph__field"></div>
      </div>
    </div>

    <div class="profile-edit-ph__block">
      <h2 class="profile-edit-ph__block-title">{{ $t('profile.emailNotifications') }}</h2>

      <div class="profile-edit-ph__fields">
        <div class="profile-edit-ph__item profile-edit-ph__field"></div>
        <div class="profile-edit-ph__item profile-edit-ph__field"></div>
      </div>
    </div>

    <div class="profile-edit-ph__block">
      <h2 class="profile-edit-ph__block-title">{{ $t('profile.settings') }}</h2>

      <div class="profile-edit-ph__fields">
        <div class="profile-edit-ph__item profile-edit-ph__field"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProfileEditPlaceholder',
  computed: {
    ...mapState('lesson', {
      lesson: (state) => state.lesson,
    }),
  },
};
</script>

<style lang="scss" scoped>
.profile-edit-ph {
  &__item {
    position: relative;
    border-radius: 5px;
    background: var(--gray-6);
    animation: shimmer 1s ease-in-out infinite;
    animation-direction: alternate;

    &--hidden {
      animation: none;
    }
  }

  &__block {
    max-width: 400px;
    margin: 0 auto 40px auto;

    @include tablet-portrait {
      max-width: none;
      margin: 0 0 60px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__avatar {
    height: 100px;
    width: 100px;
    margin: 0 auto 20px auto;
    border-radius: 50%;

    @include tablet-portrait {
      height: 88px;
      width: 88px;
      margin: 0 0 30px 0;
    }
  }

  &__avatar-remove {
    height: 20px;
    width: 58px;
    margin: 0 auto 30px auto;

    @include phone-landscape {
      display: none;
    }
  }

  &__block-title {
    align-items: center;
    justify-content: space-between;
    font-size: $header-sm;
    font-weight: $semiBold;
    margin: 0 0 25px 0;

    &--main {
      display: none;

      @include tablet-portrait {
        display: flex;
      }
    }
  }

  &__fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__field {
    position: relative;
    width: 100%;
    height: 60px;
    margin: 0 0 8px 0;
    border-radius: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    &--half {
      @include tablet-portrait {
        width: calc(50% - 4px);
      }
    }
  }

  &__field-note-line-1 {
    height: 18px;
    width: 90%;
    margin: 10px 0 0 0;

    @include phone-landscape {
      height: 18px;
      width: 70%;
    }
  }

  &__field-note-line-2 {
    height: 18px;
    width: 20%;
    margin: 1px 0 0 0;

    @include phone-landscape {
      display: none;
    }
  }

  &__save-profile {
    height: 34px;
    width: 108px;
    margin-top: 20px;
  }

  &__save-password {
    height: 34px;
    width: 146px;
    margin-top: 20px;
  }
}
</style>
