<template>
  <div class="quick-tips" :class="{ 'quick-tips--lock': lockScroll }" ref="quick-tips">
    <div class="quick-tips__list" v-if="videos.length">
      <transition-group name="tips">
        <quick-tips-item
          ref="videos"
          v-for="(video, index) in videos"
          :video="video"
          :index="index"
          :key="video.wistia_video_id" />
      </transition-group>

      <transition name="appear">
        <quick-tips-loader class="quick-tips__loader" v-if="quickTipsLoading" />
      </transition>

      <transition name="appear">
        <div class="quick-tips__no-more" v-if="noMoreQuickTips">
          {{ $t('quickTips.noMoreQuickTips') }}
        </div>
      </transition>
    </div>

    <quick-tips-placeholder v-else />

    <transition name="mobile-popup">
      <quick-tips-comments v-if="commentsOpened" />
    </transition>

    <transition :name="isPhone ? 'mobile-popup' : 'scale'">
      <quick-tips-share v-if="shareOpened" />
    </transition>
  </div>
</template>

<script>
import QuickTipsPlaceholder from './QuickTipsPlaceholder.vue';
import QuickTipsItem from './QuickTipsItem.vue';
import QuickTipsComments from './QuickTipsComments.vue';
import QuickTipsShare from './QuickTipsShare.vue';
import QuickTipsLoader from './QuickTipsLoader.vue';
import { mapState, mapGetters } from 'vuex';
import { isPhone } from '@shared/base/appFlags';
import NProgress from 'nprogress';

export default {
  components: {
    QuickTipsPlaceholder,
    QuickTipsItem,
    QuickTipsComments,
    QuickTipsShare,
    QuickTipsLoader,
  },
  data() {
    return {
      page: 0,
      perPage: 5,
    };
  },
  computed: {
    ...mapState('quickTips', [
      'commentsOpened',
      'shareOpened',
      'quickTipsLoading',
      'noMoreQuickTips',
      'video',
      'lockScroll',
      'initialAutoPromotionShown',
    ]),
    ...mapGetters('quickTips', ['videos']),
    canLoadQuickTips() {
      return !this.quickTipsLoading && !this.noMoreQuickTips;
    },
    fetchFilters() {
      return {
        per_page: this.perPage,
        page: this.page + 1,
        video_id: this.$router.currentRoute.value.params.id || null,
      };
    },
    canShowSwipeIndicator() {
      return !this.commentsOpened && !this.shareOpened;
    },
  },
  methods: {
    lockBody(val) {
      document.body.style.overflow = val ? 'hidden' : '';
    },
    loadMoreQuickTips() {
      this.$store.dispatch('quickTips/fetchQuickTips', {
        filters: this.fetchFilters,
        includes: 'include=likesCount,commentsCount',
      });
    },
    infiniteScroll() {
      const quickTips = this.$refs['quick-tips'];
      quickTips.onscroll = (e) => {
        this.$store.dispatch('quickTips/toggleShowSwipeIndicator', false);
        const margin = 10;
        const quickTipsContentHeight = quickTips.scrollHeight - quickTips.clientHeight - margin;
        const bottomOfWindow = e.target.scrollTop >= quickTipsContentHeight;
        if (!this.canLoadQuickTips) return;

        if (bottomOfWindow) {
          this.page++;
          this.loadMoreQuickTips();
        }
      };
    },
    handleKeyboardEvents(e) {
      if (![38.48].includes(e.keyCode)) return;
      let currentVideoIndex = this.$refs.videos.findIndex((vRef) => vRef.video === this.video);

      const videoRef = this.$refs.videos[e.keyCode === 38 ? currentVideoIndex-- : currentVideoIndex++];

      if (videoRef) {
        this.$store.dispatch('quickTips/setVideo', videoRef.video);
        videoRef.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
  },
  mounted() {
    this.lockBody(true);
    this.infiniteScroll();
    Promise.all([
      this.$store.dispatch('quickTips/resetQuickTips'),
      this.$store.dispatch('quickTips/fetchQuickTips', {
        filters: this.fetchFilters,
        includes: 'include=likesCount,commentsCount',
      }),
    ]).finally(() => {
      NProgress.done();
    });

    this.$nextTick(() => {
      window.addEventListener('keyup', this.handleKeyboardEvents);
    });
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.handleKeyboardEvents);
    this.lockBody(false);
  },
};
</script>

<style lang="scss" scoped>
.quick-tips {
  height: calc(100vh - #{$app-header-height});
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &--lock {
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__list {
    position: relative;
    padding: 0 0 80px 0;
  }

  &__loader,
  &__no-more {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }

  &__no-more {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    line-height: 12px;
    text-align: center;
    padding: 20px 0;
  }

  .tips-enter-active,
  .tips-leave-active {
    transition: all 0.2s ease-in-out;
  }
  .tips-enter-from,
  .tips-leave-to {
    opacity: 0;
    transform: translateY(100px);
  }

  .mobile-popup-enter-active,
  .mobile-popup-leave-active {
    transition: transform 0.4s ease-in-out;
  }
  .mobile-popup-enter-from,
  .mobile-popup-leave-active {
    transform: translate(-50%, 100%);
  }
}
</style>
