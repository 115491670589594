<template>
  <div class="commenter">
    <div class="commenter__inner">
      <app-avatar class="commenter__avatar" :src="avatarImage"></app-avatar>

      <div class="commenter__main">
        <div class="commenter__nest" v-click-outside="hideEmojis">
          <auto-sized-textarea ref="autoSizer">
            <textarea
              rows="1"
              :placeholder="`${$t('comments.leaveAComment')}...`"
              class="commenter__textarea"
              :class="{ 'commenter__textarea--with-picker': !isMobile }"
              ref="textarea"
              v-model="commentText"
              :disabled="sending"
              @keydown.enter.prevent
              @keyup.enter.prevent="addComment"
              @focus="handleFocus"
              @blur="resetFocus"></textarea>
          </auto-sized-textarea>

          <div class="commenter__options" v-if="!guest && !isMobile">
            <div
              class="commenter__options-btn commenter__emoji-btn"
              :class="{ 'commenter__emoji-btn--active': isEmojiPicker }"
              @click="toggleEmojis">
              <font-awesome-icon :icon="['far', 'face-smile']" />
            </div>

            <transition-expand down>
              <div class="commenter__emoji-nest" ref="emojiNest" v-show="isEmojiPicker">
                <Picker :data="emojiIndex" :showPreview="false" @select="emojiSelect" />
              </div>
            </transition-expand>
          </div>
        </div>
      </div>

      <a class="commenter__send commenter__send--sending" v-if="sending">
        <font-awesome-icon :icon="['fas', 'spinner']" spin />
      </a>
      <a
        class="commenter__send"
        :class="{ 'commenter__send--active': commentText.length }"
        @click.stop="addComment"
        v-else-if="!guest"
        >{{ $t('send') }}</a
      >
    </div>
  </div>
</template>

<script>
import AutoSizedTextarea from '@shared/components/utils/AutoSizedTextarea.vue';
import TransitionExpand from '@shared/components/utils/TransitionExpand.vue';
import clickOutside from '@shared/directives/clickOutside.js';
import insertText from 'insert-text-at-cursor';
import data from 'emoji-mart-vue-fast/data/all.json';
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src';
import { mapGetters, mapState } from 'vuex';

const emojiIndex = new EmojiIndex(data);

export default {
  name: 'LessonCommenter',
  components: {
    Picker,
    AutoSizedTextarea,
    TransitionExpand,
  },
  props: {
    focus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      commentText: '',
      isEmojiPicker: false,
      emojiIndex: emojiIndex,
      sending: false,
    };
  },
  computed: {
    ...mapGetters('user', ['avatarImage']),
    ...mapState('quickTips', {
      postComment: (state) => state.postComment,
      commentFocus: (state) => state.commentFocus,
      replyTo: (state) => state.replyTo,
    }),
    ...mapState('user', ['guest']),
    isAvatar() {
      return !this.avatarImage.includes('placeholder');
    },
  },
  watch: {
    commentFocus: {
      handler(val) {
        if (val) {
          this.$refs.textarea.focus();
        }
      },
      immediate: true,
    },
    replyTo: {
      handler(val) {
        if (val) {
          this.commentText = `@${val.user.first_name} ${val.user.last_name} `;
        } else {
          this.commentText = '';
        }
      },
    },
  },
  directives: {
    'click-outside': clickOutside,
  },
  methods: {
    handleFocus() {
      this.$store.dispatch('quickTips/setCommentFocus', true);
    },
    resetFocus() {
      this.$store.dispatch('quickTips/setCommentFocus', false);
    },
    toggleEmojis() {
      this.isEmojiPicker = !this.isEmojiPicker;
      this.$refs.textarea.focus();

      if (this.isEmojiPicker) {
        setTimeout(() => this.$refs.emojiNest.scrollIntoView({ behavior: 'smooth', block: 'nearest' }), 400);
      }
    },
    hideEmojis() {
      this.isEmojiPicker = false;
    },
    emojiSelect(emoji) {
      insertText(this.$refs.textarea, emoji.native);
      this.hideEmojis();
    },
    addComment() {
      if (this.guest) return;
      if (this.commentText.length) {
        this.sending = true;

        this.$store
          .dispatch('quickTips/addComment', {
            content: this.commentText,
            replyId: this.replyTo?.is_reply ? this.replyTo?.parentId : this.replyTo?.id,
          })
          .then(() => {
            this.sending = false;
            this.commentText = '';
          })
          .catch(() => {
            this.sending = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.commenter {
  position: relative;

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__nest {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__main {
    flex: 1;
  }

  &__options {
    display: flex;
    align-items: center;
  }

  &__options-btn {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 22px;
    color: var(--gray-3);
    padding: 15px;
    cursor: pointer;
  }

  &__emoji-btn {
    &--active {
      color: var(--green-light);
    }
  }

  &__emoji-nest {
    position: absolute;
    bottom: 100%;
    right: -69px;
    z-index: 10;
  }

  &__avatar {
    height: 36px;
    min-width: 36px;
    max-width: 36px;
    z-index: 2;
  }

  &__textarea {
    display: block;
    width: 100%;
    height: 74px;
    min-height: 74px;
    font-size: 16px;
    line-height: 20px;
    color: var(--gray-1);
    border: 0;
    padding: 27px 16px;
    outline: none;
    background: none;
    overflow: hidden;
    resize: none;
    -webkit-appearance: none;

    &::placeholder {
      color: var(--gray-1);
    }
  }

  &__send {
    display: block;
    width: 55px;
    line-height: 48px;
    font-weight: $semiBold;
    text-align: center;
    opacity: 0.2;
    cursor: pointer;
    pointer-events: none;
    transition: all 0.2s ease-in-out;

    &--active {
      opacity: 1;
      pointer-events: all;
    }
  }
}
</style>
