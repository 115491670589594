<template>
  <div class="course-overview" v-if="course">
    <div class="course-overview__bg" :style="{ 'background-image': `url(${cover})` }">
      <!-- <div class="course-overview__timer" v-if="guest">
        <div class="course-overview__timer-title">80% Discount<br> Ends Tonight</div>
        <app-timer class="overview-timer" :end="endDate" :showDays="false"></app-timer>
      </div> -->
    </div>
    <div class="course-overview__text">
      <div class="course-overview__text-bg" :style="{ 'background-image': `url(${cover})` }"></div>
      <div class="course-overview__text-inner">
        <h3 class="course-overview__title">{{ course.title }}</h3>
        <p class="course-overview__desc" v-html="description"></p>
        <a
          :href="handleSalesPageLinkParams(lesson.locked_url)"
          target="_blank"
          class="course-overview__button"
          @click="trackCtaClick"
          ><span>{{ $t('lesson.getInstantAccess') }}</span></a
        >
      </div>
    </div>
  </div>
  <div class="course-overview__placeholder" v-else></div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('user', {
      guest: (state) => state.guest,
    }),
    ...mapState('course', {
      course: (state) => state.course,
    }),
    ...mapState('lesson', {
      lesson: (state) => state.lesson,
      module: (state) => state.module,
    }),
    coverSize() {
      return Math.ceil(this.pixelRatio * window.innerWidth);
    },
    cover() {
      return `https://images.iphonephotographyschool.com/${this.course.cover_wide}/${this.coverSize}/${this.course.product_slug}.jpg`;
    },
    endDate() {
      let nextMidnight = new Date();

      nextMidnight.setHours(24, 0, 0, 0);

      return nextMidnight.getTime();
    },
    description() {
      return this.course.description.replace(/\n/g, '<br>');
    },
  },
  mounted() {
    this.$store.dispatch('timer/startTimer');
  },
  methods: {
    trackCtaClick() {
      this.trackEvent('button_clicked', {
        button: 'lesson_view_course_overview_cta',
        course_id: this.course.id,
        course_slug: this.course.product_slug,
        course_name: this.course.title,
        module_id: this.module.id,
        module_name: this.module.title,
        lesson_id: this.lesson.id,
        lesson_name: this.lesson.title,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.course-overview {
  position: relative;

  @include phone-landscape {
    display: flex;
    padding: 0 0 0 40%;
    margin: 0 0 68px 0;
    border-radius: 8px;
    background: var(--gray-6);
  }

  &__bg {
    height: 60vw;
    width: 100vw;
    border-radius: 6px 6px 0 0;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--gray-6);
    transform: translateX(-10px);

    @include phone-landscape {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 40%;
      border-radius: 6px 0 0 6px;
      background-position: 30% 50%;
      background-color: none;
      transform: none;
    }
  }

  &__timer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px 16px;
    background: rgba(var(--gray-8-rgb), 0.9);

    @include phone-landscape {
      flex-flow: column;
      justify-content: center;
    }
    @include desktop-sm {
      flex-flow: row;
      justify-content: space-between;
    }
  }

  &__timer-title {
    font-size: $text-md;
    font-weight: $bold;
    line-height: 1.2;
    margin: 0;

    @include phone-portrait {
      font-size: $text-lg;
    }
    @include phone-landscape {
      margin: 0 0 8px 0;
    }
    @include desktop-sm {
      margin: 0;
    }

    br {
      @include phone-landscape {
        display: none;
      }
      @include desktop-sm {
        display: block;
      }
    }
  }

  &__text {
    position: relative;
    padding: 2px 2px 2px 0;
    border-radius: 0 6px 6px 0;
    overflow: hidden;
    flex-grow: 1;
  }

  &__text-inner {
    position: relative;
    padding: 24px 6px;

    @include phone-landscape {
      display: flex;
      flex-flow: column;
      justify-content: center;
      min-height: 400px;
      padding: 50px 32px;
      border-radius: 0 6px 6px 0;
      background: var(--black);
    }
  }

  &__text-bg {
    display: none;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0;
    right: -2px;
    border-radius: 0 6px 6px 0;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(10px);

    @include phone-landscape {
      display: block;
    }
  }

  &__title {
    font-weight: $semiBold;
    font-size: 28px;
    line-height: 34px;
    margin: 0 0 24px 0;

    @include phone-landscape {
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__desc {
    line-height: 1.5;
    margin: 0 0 24px 0;
  }

  &__button {
    display: block;
    position: relative;
    width: 100%;
    font-size: 21px;
    line-height: 60px;
    font-weight: $bold;
    text-align: center;
    text-decoration: none;
    color: var(--white);
    border-radius: 6px;
    background: linear-gradient(165deg, #d41c3d 0%, #861b83 100%);
    transition: all 0.2s ease-in-out;

    &:hover {
      background: linear-gradient(182deg, #d41c3d 0%, #861b83 100%);
    }

    span {
      position: relative;
    }
  }

  &__placeholder {
    height: 440px;
    border-radius: 8px;
    background: var(--gray-6);
    animation: shimmer 1s ease-in-out infinite;
    animation-direction: alternate;
  }
}
</style>
