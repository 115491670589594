<template>
  <div class="quick-tips-video" ref="video" @click="handleVideoClickEvent">
    <canvas class="quick-tips-video__ambilight" ref="ambilight" v-if="!isPhone"></canvas>

    <div class="wistia_responsive_padding" style="padding: 177.71% 0 0 0; position: relative">
      <div class="wistia_responsive_wrapper" style="height: 100%; left: 0; position: absolute; top: 0; width: 100%">
        <div
          class="wistia_embed"
          :class="`wistia_async_${video.wistia_video_id}`"
          style="height: 100%; position: relative; width: 100%">
          <div
            class="wistia_swatch"
            style="
              height: 100%;
              left: 0;
              opacity: 0;
              overflow: hidden;
              position: absolute;
              top: 0;
              transition: opacity 200ms;
              width: 100%;
            ">
            <img
              :src="`https://fast.wistia.com/embed/medias/${video.wistia_video_id}/swatch`"
              style="filter: blur(5px); height: 100%; object-fit: contain; width: 100%"
              aria-hidden="true"
              onload="this.parentNode.style.opacity=1;" />
          </div>
        </div>
      </div>
      <transition name="fade">
        <app-line-loader class="quick-tips-video__loader" v-if="isLoading" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppLineLoader from '@course-portal/components/ui/AppLineLoader.vue';

export default {
  components: {
    AppLineLoader,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    video: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('quickTips', {
      activeVideo: (state) => state.video,
      videoAutoplay: (state) => state.videoAutoplay,
      autoLoginPopupShown: (state) => state.autoLoginPopupShown,
      autoPromotionShown: (state) => state.autoPromotionShown,
      autoPromotionShownVideos: (state) => state.autoPromotionShownVideos,
      muted: (state) => state.muted,
    }),
    ...mapState('user', ['guest']),
    ...mapState('layout', ['appPromotion']),
  },
  data() {
    return {
      observer: null,
      isLoading: false,
      startedWatching: false,
      isInSilentPlaybackMode: false,
    };
  },
  methods: {
    loadVideo() {
      window._wq = window._wq || [];
      window._wq.push({
        id: this.video.wistia_video_id,
        options: {
          seo: false,
          videoFoam: true,
          playbar: false,
          qualityMax: '1080',
          endVideoBehavior: 'loop',
          smallPlayButton: false,
          bigPlayButton: true,
          autoPlay: this.index === 0 ? true : false,
          controlsVisibleOnLoad: false,
          settingsControl: false,
          volumeControl: false,
          muted: this.muted,
          preload: this.index === 0 ? 'auto' : 'metadata',
          fullscreenButton: false,
          disableCaptions: true,
          plugin: {
            chapters: {
              on: false,
            },
          },
        },
        onReady: (video) => {
          this.$nextTick(() => {
            this.bindMuteChangeEvent(video);
            this.bindPlayPauseEvents(video);
            if (!this.$refs['video']) return;
            this.snapAction();
            this.ambilightEffect(video);
            if (this.guest) return;
            this.trackProgress(video);
            this.setupVideoTrackerEvents();
          });
        },
      });
    },
    fireTrackerEvent(action, name) {
      this.trackEvent(action, {
        ...(name && { button: name }),
        quick_tip_id: this.video.id,
        quick_tip_title: this.video.title,
      });
    },
    bindPlayPauseEvents(video) {
      if (this.isMobile) {
        video.bind('silentplaybackmodechange', (inSilentPlaybackMode) => {
          this.isInSilentPlaybackMode = inSilentPlaybackMode;
        });

        const videoWrapper = this.$refs['video'].querySelector('.wistia_responsive_wrapper');
        videoWrapper?.addEventListener('touchstart', this.handleVideoTouchEvent);
      }
    },
    setupVideoTrackerEvents() {
      const videoElem = this.$refs['video'].querySelector('.wistia_responsive_wrapper video');

      videoElem.addEventListener('play', () => {
        if (!this.startedWatching) {
          this.startedWatching = true;
          this.fireTrackerEvent('quick_tips_started_watching');
          return;
        }
        this.fireTrackerEvent('button_clicked', 'quick_tips_view_tap_view_play');
      });

      videoElem.addEventListener('pause', () =>
        this.fireTrackerEvent('button_clicked', 'quick_tips_view_tap_view_pause'),
      );
    },
    ambilightEffect(video) {
      const FRAMERATE = 30;
      const videoNest = this.$refs['video'];
      const posterElem = '.wistia_responsive_wrapper [data-handle="thumbnail"] img';
      const videoElem = this.$refs['video'].querySelector('.wistia_responsive_wrapper video');
      const canvas = this.$refs['ambilight'];
      // this throws an error when the page is loading
      if (!canvas || !videoElem) return;

      const context = canvas.getContext('2d');
      let width;
      let height;
      let intervalId;

      const drawAmbilight = () => {
        width = parseInt((videoNest.clientHeight * video.aspect()).toFixed(0));
        height = parseInt(videoNest.clientHeight.toFixed(0));

        canvas.width = width;
        canvas.height = height;

        repaintAmbilight();
      };
      const repaintAmbilight = () => {
        video.state() === 'beforeplay'
          ? this.waitForElement(posterElem).then((elem) => context.drawImage(elem, 0, 0, width, height))
          : context.drawImage(videoElem, 0, 0, width, height);
      };
      const startAmbilightRepaint = () => {
        intervalId = window.setInterval(repaintAmbilight, 1000 / FRAMERATE);
      };
      const stopAmbilightRepaint = () => {
        clearInterval(intervalId);
      };

      this.afterOrientationChange(drawAmbilight);
      window.addEventListener('resize', drawAmbilight);

      videoElem.addEventListener('play', startAmbilightRepaint);
      videoElem.addEventListener('pause', stopAmbilightRepaint);
      videoElem.addEventListener('ended', stopAmbilightRepaint);
      videoElem.addEventListener('seeked', repaintAmbilight);
      videoElem.addEventListener('load', repaintAmbilight);

      // undocumented events from wistia's playPauseLoadingControl.js asset,
      // we'd have to keep an eye on updates to the wistia api to make sure these events still work
      video.on('waiting', () => {
        this.isLoading = true;
      });
      video.on('done-waiting', () => {
        this.isLoading = false;
      });

      drawAmbilight();
    },
    handleVideoTouchEvent() {
      const video = Wistia.api(this.video.wistia_video_id);
      if (!this.isInSilentPlaybackMode) {
        video.state() === 'playing' ? video.pause() : video.play();
      }
    },
    trackProgress(video) {
      let maxSecs = 0;
      let PROGRESS_THRESHOLD = 5;
      let currentThreshold = PROGRESS_THRESHOLD;

      video.bind('secondchange', (seconds) => {
        if (seconds < maxSecs) {
          video.unbind('secondchange');
          return;
        }
        if (video.state() === 'playing') {
          if (video.data.media.duration - seconds < 1) {
            this.fireTrackerEvent('quick_tips_view_reel_completed');
            this.$store.dispatch('quickTips/setVideoProgress', seconds);
            video.unbind('secondchange');
            return;
          }
          if (seconds >= currentThreshold) {
            currentThreshold += PROGRESS_THRESHOLD;
            this.$store.dispatch('quickTips/setVideoProgress', seconds);
          }
          maxSecs = Math.max(maxSecs, seconds);
        }
      });
    },
    snapAction() {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((elem) => {
            if (elem.isIntersecting) {
              this.$store.dispatch('quickTips/setVideo', this.video).then((skippedVideo) => {
                if (skippedVideo) {
                  this.fireTrackerEvent('quick_tips_skipped');
                }
              });
              if (
                !this.appPromotion &&
                !(this.index % (this.isPhone ? 9 : 4)) &&
                !this.autoPromotionShownVideos.includes(this.index)
              ) {
                this.$store.dispatch('quickTips/setAutoPromotionShown', this.index);
                this.$store.dispatch('layout/toggleAppPromotion', 'auto', { root: true });
                this.trackEvent('button_clicked', { button: 'get_app_popup_auto_shown' });
              }
              this.toggleVideoAutoplay(true);
            } else {
              this.toggleVideoAutoplay(false);
            }
          });
        },
        {
          root: null,
          threshold: 1,
        },
      );

      this.observer.observe(this.$refs['video']);
    },
    bindMuteChangeEvent(video) {
      video.on('mutechange', (isMuted) => {
        isMuted !== this.muted && this.$store.dispatch('quickTips/setMuted', isMuted);
      });
    },
    toggleVideoAutoplay(play) {
      const video = Wistia.api(this.video.wistia_video_id);
      play ? video.play() : video.pause();
    },
    handleVideoClickEvent(event) {
      if (this.activeVideo !== this.video) {
        event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    handlePullToRefreshEvent() {
      let lastScrollPosition = 0;

      window.onscroll = () => {
        if (document.body.scrollTop >= 0 && lastScrollPosition < 0) {
          this.trackEvent('quick_tips_view_swipe_pull_to_refresh');
        }

        lastScrollPosition = document.body.scrollTop;
      };
    },
  },
  watch: {
    videoAutoplay(val) {
      if (this.activeVideo === this.video) {
        this.toggleVideoAutoplay(val);
      }
    },
    appPromotion() {
      if (this.appPromotion && this.index === 0) {
        this.$store.dispatch('quickTips/setAutoPromotionShown', this.index);
        this.$store.dispatch('layout/toggleAppPromotion', 'manual', { root: true });
      }
    },
    muted(val) {
      const video = Wistia.api(this.video.wistia_video_id);
      val ? video.mute() : video.unmute();
    },
  },
  mounted() {
    this.loadVideo();
    this.handlePullToRefreshEvent();
  },
  beforeUnmount() {
    this.observer.disconnect();
    this.observer = null;
    const video = Wistia.api(this.video.wistia_video_id);
    video?.pause();
    video?.remove();
  },
};
</script>

<style lang="scss">
.quick-tips-video {
  position: relative;
  height: 100%;

  &__ambilight {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    transform: translate(-50%, -50%);
    transition: opacity 0.4s ease-in-out;
    filter: blur(40px) opacity(0.4) saturate(150%);
    pointer-events: none;

    @include tablet-portrait(portrait) {
      filter: blur(100px) opacity(0.5) saturate(150%);
    }
    @include tablet-landscape {
      filter: blur(100px) opacity(0.5) saturate(150%);
    }
  }

  .wistia_responsive_padding {
    position: absolute !important;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);

    @include phone-landscape {
      position: relative !important;
      top: auto;
      border-radius: 16px;
      transform: none;
      overflow: hidden;
      // iPad Safari border radius fix
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
    }

    .wistia_swatch {
      border-radius: 16px;
    }
  }

  .wistia_embed {
    &_initialized {
      * {
        cursor: pointer !important;
      }
    }
    .w-big-play-button,
    div[data-handle='playPauseLoading'] {
      display: none !important;
    }
  }

  .w-video-wrapper {
    background: transparent !important;
  }

  .w-bottom-bar {
    display: none !important;
  }
  &__loader {
    position: absolute;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    bottom: 1px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease-in-out;
  }
  .fade-enter-from,
  .fade-leave-active {
    opacity: 0;
  }
}
</style>
