<script>
export default {
  name: 'AutoSizedTextarea',
  methods: {
    resizeTextarea() {
      this.$el.style.height = 'auto';
      this.$el.style.height = this.$el.scrollHeight + 'px';
    },
    resetTextarea() {
      this.$el.style.height = 'auto';
    },
  },
  mounted() {
    this.$el.addEventListener('input', this.resizeTextarea);
    window.addEventListener('resize', this.resizeTextarea);
  },
  beforeUnmount() {
    this.$el.removeEventListener('input', this.resizeTextarea);
    window.removeEventListener('resize', this.resizeTextarea);
  },
  render() {
    return this.$slots.default()[0];
  },
};
</script>
