<template>
  <div class="achievements-item">
    <div
      class="achievements-item__nest"
      :class="{ 'achievements-item__nest--with-progress': hasProgress }"
      ref="achievement">
      <div class="achievements-item__img-nest">
        <app-progress-radial
          class="achievements-item__progress"
          :id="achievement.key"
          :diameter="diameter"
          :completed-steps="progress"
          :total-steps="achievement.progress.progress_goal ? achievement.progress.progress_goal : 100"
          :strokeWidth="3"
          :startColor="'#ED1C24'"
          :stopColor="'#92278F'"
          :innerStrokeWidth="3"
          :innerStrokeColor="'transparent'"
          :timingFunc="'ease-out'"
          v-if="achievement.progress">
        </app-progress-radial>

        <img
          :src="achievement.image"
          alt="img"
          class="achievements-item__badge"
          @load="loaded = true"
          v-show="loaded" />
        <div class="achievements-item__badge achievements-item__badge--loading" v-show="!loaded"></div>
      </div>

      <p class="achievements-item__title">
        {{ achievement.title }} <span v-if="achievement.progress && showProgress">{{ `(${showProgress})` }}</span>
      </p>
    </div>
  </div>
</template>

<script>
// Constant values
const RADIAL_PROGRESS_DIAMETER = 85;
const RADIAL_PROGRESS_DIAMETER_MOBILE = 77;

import { mapState } from 'vuex';

export default {
  name: 'ProfileAchievementsItem',
  props: {
    achievement: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      diameter: this.isPhone ? RADIAL_PROGRESS_DIAMETER_MOBILE : RADIAL_PROGRESS_DIAMETER,
      progress: 0,
    };
  },
  computed: {
    ...mapState('profile', {
      achievementHighlighted: (state) => state.achievementHighlighted,
    }),
    hasProgress() {
      return this.achievement.hasOwnProperty('progress');
    },
    showProgress() {
      return this.achievement.progress.progress_goal
        ? this.achievement.progress.progress_units
          ? `${this.achievement.progress.progress_units}/${this.achievement.progress.progress_goal}`
          : null
        : this.achievement.progress.progress_percentage
          ? `${this.percentageProgress}%`
          : null;
    },
    percentageProgress() {
      return Math.floor(this.achievement.progress.progress_percentage * 100);
    },
    isHighlighted() {
      if (!this.achievementHighlighted) {
        return;
      }

      return this.achievement.title === this.achievementHighlighted.data.achievement.name;
    },
  },
  methods: {
    startProgressAnimation() {
      setTimeout(() => {
        this.progress = this.achievement.progress
          ? this.achievement.progress.progress_units
            ? this.achievement.progress.progress_units
            : this.percentageProgress
          : 0;
      }, 600);
    },
    highlightAchievement() {
      if (!this.isHighlighted) {
        return;
      }

      const box = this.$refs[`achievement`];

      // Briefly highlight the comment when in viewport
      const intersectionObserver = new IntersectionObserver((entries) => {
        let [entry] = entries;

        if (entry.isIntersecting) {
          box.classList.add('achievements-item__nest--highlighted');
          setTimeout(() => {
            box.classList.remove('achievements-item__nest--highlighted');
            intersectionObserver.unobserve(box);
          }, 6000);
        }
      });

      intersectionObserver.observe(box);

      box.scrollIntoView({ behavior: 'smooth', block: this.isMobile ? 'start' : 'center' });

      setTimeout(() => {
        this.$store.dispatch('profile/setAchievementHighlighted', null);
      }, 5000);
    },
  },
  mounted() {
    this.startProgressAnimation();
    setTimeout(() => this.highlightAchievement(), 1000);
  },
};
</script>

<style lang="scss" scoped>
.achievements-item {
  width: 33.3333333%;
  text-align: center;
  padding: 0 10px;

  @include tablet-portrait(portrait) {
    width: 20%;
  }
  @include tablet-landscape {
    width: 20%;
  }

  &__nest {
    padding: 15px 0;
    border-radius: 8px;
    transition: background-color 0.4s ease-in-out;

    &--highlighted {
      background: var(--gray-6);
    }
  }

  &__img-nest {
    position: relative;
    width: 82px;
    height: 82px;
    max-width: 100%;
    margin: 0 auto 7px auto;
    border-radius: 50%;

    @include tablet-portrait {
      margin: 0 auto 10px auto;
    }

    &--with-progress {
      padding: 3px;
      background: var(--gray-6);
    }
  }

  &__badge {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;

    &--loading {
      border-radius: 50%;
      background: var(--gray-6);
      animation: shimmer 1s ease-in-out infinite;
      animation-direction: alternate;
    }
  }

  &__title {
    font-size: $text-sm;
    color: var(--gray-1);
    margin: 0;

    @include tablet-portrait {
      padding: 0 10px;
    }
  }

  &__progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}
</style>
