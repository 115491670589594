<template>
  <div class="lesson" v-if="isLoaded">
    <app-container :fluid="true">
      <app-row>
        <div class="lesson__main" ref="main">
          <template v-if="lesson">
            <lesson-teaser v-if="lesson.is_video && !lesson.video_id" />
            <template v-else>
              <template v-if="lesson.is_video">
                <lesson-video v-if="lesson.video_id" v-show="!(isCourseICA && lesson?.course?.locked)"></lesson-video>
                <lesson-video-youtube v-else></lesson-video-youtube>
              </template>
              <lesson-non-video v-else></lesson-non-video>
            </template>
            <lesson-upsell-banner v-if="showUpsellBanner" />
            <div class="lesson__columns">
              <lesson-content :parent="lessonMain" />
            </div>
          </template>

          <lesson-placeholder v-else></lesson-placeholder>
        </div>

        <div
          class="lesson__modules"
          :class="{
            'lesson__modules--dim': showMenu,
            'lesson__modules--with-banner': banner,
            'lesson__modules--guest': guest,
          }"
          v-if="!isIPadPortrait">
          <sidebar :loadedFromLesson="true"></sidebar>
        </div>
      </app-row>
    </app-container>
  </div>
</template>

<script>
import AppContainer from '@course-portal/components/layout/AppContainer.vue';
import AppRow from '@course-portal/components/layout/AppRow.vue';
import LessonVideo from './LessonVideo.vue';
import LessonVideoYoutube from './LessonVideoYoutube.vue';
import LessonTeaser from './LessonTeaser.vue';
import LessonNonVideo from './LessonNonVideo.vue';
import LessonContent from './LessonContent.vue';
import LessonPlaceholder from './LessonPlaceholder.vue';
import LessonUpsellBanner from './LessonUpsellBanner.vue';
import Sidebar from '@course-portal/components/sidebar/index.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'LessonIndex',
  components: {
    AppContainer,
    AppRow,
    LessonVideo,
    LessonVideoYoutube,
    LessonTeaser,
    LessonNonVideo,
    LessonContent,
    LessonPlaceholder,
    LessonUpsellBanner,
    Sidebar,
  },
  data() {
    return {
      lessonMain: null,
      showUpsellBanner: false,
    };
  },
  computed: {
    ...mapGetters('lesson', ['singleLesson']),
    ...mapState('settings', {
      settings: (state) => state.settings,
    }),
    ...mapState('layout', {
      showMenu: (state) => state.showMenu,
      isLandscape: (state) => state.isLandscape,
    }),
    ...mapState('banner', {
      banner: (state) => state.banner,
    }),
    ...mapState('course', {
      course: (state) => state.course,
    }),
    ...mapState('lesson', {
      lesson: (state) => state.lesson,
    }),
    ...mapState('user', {
      guest: (state) => state.guest,
    }),
    isLoaded() {
      return this.guest ? true : this.settings;
    },
    isCourseICA() {
      return this.lesson?.course?.id === 15;
    },
    isTriggerPromotion() {
      return this.lesson && this.lesson.upsell_cta && this.lesson.upsell_cta.url;
    },
    isIPadPortrait() {
      return this.isIPad && !this.isLandscape;
    },
  },
  watch: {
    '$route.params.id': {
      handler(val) {
        this.handleLockedRedirect();
        // Trigger promotion if available
        if (this.isTriggerPromotion) {
          this.triggerPromotion();
        }
        // if (this.$route.params.autoplay && this.isFullscreen()) {
        //   this.nextLessonAutoplay(val);
        //   return;
        // }
        // Fetch lesson:
        // - from store if it exists
        // - from API if it doesn't
        if (this.course && this.course.id === 15) {
          this.$store.dispatch('popup/getPopup', { lesssonId: val });
        }
        this.guest ? this.loadLessonGuest() : this.loadLesson(val);
      },
    },
  },
  methods: {
    async init() {
      await this.$store.dispatch('lesson/fetchLesson', this.$route.params.id);

      this.lessonMain = this.$refs.main;

      if (this.lesson && this.isCourseICA) {
        this.showUpsellBanner = true;

        if (this.lesson?.course?.locked) {
          this.$router.push({ name: 'trial-ended' });
        }
      }

      if (this.lesson && this.settings) {
        this.$store.dispatch('course/setCourse', this.lesson.course.id);
      }
    },
    async initGuest() {
      await this.$store.dispatch('lesson/fetchSharedLesson', this.$route.params.id);

      this.lessonMain = this.$refs.main;

      if (this.lesson) {
        this.$store.dispatch('course/setSharedCourse', this.lesson.course.id);
      }
    },
    loadLessonFromCourses(val) {
      this.$store.dispatch('lesson/setLesson', parseInt(val));
      this.$store.dispatch('lesson/fetchLessonAdditionalFields', parseInt(val));
    },
    loadLesson(val) {
      if (this.$route.name === 'lesson' && this.course) {
        this.singleLesson(parseInt(val)).lesson && !this.course.locked
          ? this.loadLessonFromCourses(parseInt(val))
          : this.init();
        this.$store.dispatch('lesson/setShowUpcoming', false);
      }
    },
    loadLessonGuest() {
      if (this.course) {
        this.initGuest();
      }
    },
    // nextLessonAutoplay(id) {
    //   const video = Wistia.api(this.lesson.video_id);

    //   this.$store.dispatch('lesson/toggleNextVideoLoading', true);
    //   this.$store.dispatch('lesson/updateLesson', id).then(() => {
    //     this.$store.dispatch('lesson/toggleCountdown', false);
    //     this.$store.dispatch('comments/resetComments');
    //     this.$store.dispatch('comments/setComments', {
    //       lessonId: id,
    //       page: 1,
    //       commentId: null,
    //     });
    //     video.replaceWith(this.lesson.video_id, { ...this.options, transition: 'crossfade' });
    //     this.wistiaConfig();
    //   });
    // },
    triggerPromotion() {
      Promise.all([
        this.$store.dispatch('courses/resetCourses'),
        this.$store.dispatch('courses/setCourses'),
        this.$store.dispatch('banner/getBanner'),
      ]).then(() => {
        this.$store.dispatch('timer/startTimer');
      });
    },
    handleLockedRedirect() {
      if (!this.isAppMode) return;

      if (this.lesson.course?.locked) {
        this.$store.dispatch('notifications/showToast', {
          item: {
            type: 'error',
            content: {
              message: 'You need to unlock the course to access this&nbsp;lesson.',
            },
          },
          isMobile: this.isMobile,
        });

        this.$router.push({ name: 'home' });
      }
    },
  },
  mounted() {
    this.guest ? this.initGuest() : this.init();
  },
  beforeUnmount() {
    this.$store.dispatch('course/resetCourse');
    this.$store.dispatch('lesson/resetLesson');
    this.$store.dispatch('layout/setHeaderTitle', null);
  },
  beforeRouteLeave() {
    if (this.isTriggerPromotion) {
      this.triggerPromotion();
    }
  },
};
</script>

<style lang="scss" scoped>
.lesson {
  &__main {
    flex: 1;
    justify-self: flex-end;
    position: relative;
    max-width: 100vw;

    @include tablet-landscape {
      min-height: 110vh;
      max-width: calc(100% - 350px);
      padding: 14px 0 0 14px;
    }
    @include desktop-xs {
      max-width: calc(100% - 400px);
    }
    @include desktop-sm {
      max-width: calc(100% - 460px);
    }
    @include desktop-lg {
      max-width: calc(100% - 500px);
    }
    @include ipad-pro-129(portrait) {
      min-height: 0;
      max-width: 100vw;
      padding: 0;
    }

    :deep(.course-modules) {
      @include tablet-portrait(portrait) {
        max-width: none;
      }
    }
  }

  &__columns {
    display: flex;
    justify-content: center;
  }

  &__modules {
    display: none;
    position: relative;
    transition: opacity 0.2s ease-in-out;
    z-index: 200;
    @include hide-scrollbar;

    @include tablet-landscape {
      display: block;
      flex: 0 0 350px;
      @supports (position: sticky) {
        top: $app-header-height;
        position: sticky;
        height: calc(100vh - #{$app-header-height});
      }
    }
    @include desktop-xs {
      flex: 0 0 400px;
    }
    @include desktop-sm {
      flex: 0 0 460px;
    }
    @include desktop-lg {
      flex: 0 0 500px;
    }
    @include ipad-pro-129(portrait) {
      display: none;
    }

    &--with-banner {
      @include tablet-landscape {
        @supports (position: sticky) {
          top: calc(#{$app-header-height} + #{$app-banner-height});
          height: calc(100vh - #{$app-header-height} - #{$app-banner-height});
        }
      }
    }

    &--dim {
      opacity: 0.3;
    }

    &--guest {
      @include tablet-landscape {
        @supports (position: sticky) {
          top: $app-header-guest-height;
          height: calc(100vh - #{$app-header-guest-height});
        }
      }
    }
  }
}
</style>
