import appClient from '@shared/api/appClient';

export default {
  namespaced: true,
  namespace: 'courses',
  state: {
    courses: null,
    coursesLoadedOnce: false,
    reloadCourses: false,
  },
  getters: {
    courseOnSale: (state) => {
      if (!state.courses) return;
      const course = state.courses.find((item) => item.sales_config);
      if (course) {
        return {
          course: course,
          salesConfig: course.sales_config,
          ios_locked_params: course.ios_locked_params,
        };
      }
    },
    ownedCourses: (state) => {
      return state.courses?.filter((item) => !item.locked);
    },
    lockedCourses: (state, getters) => {
      return state.courses?.filter((item) => item.locked && item.id !== getters.courseOnSale?.course.id);
    },
    isLimitedSpots: (state, getters) => {
      return getters.courseOnSale?.salesConfig.order_limit;
    },
    singleCourse: (state) => (id) => {
      const course = state.courses.find((item) => {
        return item.id === id;
      });

      return course;
    },
    firstLesson: (state) => {
      if (state.courses && state.courses.length) {
        return state.courses[0].current_lesson?.id;
      }
    },
    hasIPACourse: (state) => {
      if (state.courses) {
        const group = state.courses.filter((item) => !item.locked && item.product_slug === 'iphone-photo-academy');
        return group.length > 0;
      }
    },
    isIPAGroup: (state) => {
      if (state.courses) {
        const group = state.courses.filter((item) => !item.locked && item.domain === 'ips');
        return group.length > 0;
      }
    },
    isDCMGroup: (state) => {
      if (state.courses) {
        const group = state.courses.filter((item) => !item.locked && item.domain === 'pp');
        return group.length > 0;
      }
    },
  },
  actions: {
    setCourses({ commit, state }) {
      if (state.courses) {
        return;
      }

      commit('SET_COURSES', null);

      return appClient
        .getCourses()
        .then(({ data: courses }) => {
          if (!courses || !courses.length) return;

          const isSaleActive = courses.some((course) => course.sales_config?.sale_name);

          if (!isSaleActive) return courses;

          const firstLockedCourseWithSale = courses.find((course) => course.sales_config?.sale_name);
          const otherCourses = courses.filter((course) => course.id !== firstLockedCourseWithSale.id);
          const coursesArray = [firstLockedCourseWithSale, ...otherCourses];
          const courseClosed = coursesArray.find((item) => item.sales_config?.is_registration_closed);

          if (courseClosed) {
            coursesArray.push(coursesArray.splice(coursesArray.indexOf(courseClosed), 1)[0]);
          }

          return coursesArray;
        })
        .then((courses) => {
          if (courses) {
            const coursesWithIosLockedUrl = courses.map((course) => ({
              ...course,
              ios_locked_params: null,
            }));
            commit('SET_COURSES', coursesWithIosLockedUrl);
          }
        });
    },
    resetCourses({ commit }) {
      commit('RESET_COURSES');
    },
    reloadCourses({ commit }, value) {
      commit('RELOAD_COURSES', value);
    },
    updateProgress({ commit }, payload) {
      commit('UPDATE_PROGRESS', {
        courseId: payload.courseId,
        courseProgress: payload.courseProgress,
      });
    },
    updateCurrentLesson({ commit, getters }, payload) {
      const course = getters.singleCourse(payload.courseId);

      commit('UPDATE_CURRENT_LESSON', {
        course: course,
        currentLessonId: payload.currentLessonId,
      });
    },
    endSales({ commit }, cardId) {
      commit('END_SALES', cardId);
    },
  },
  mutations: {
    SET_COURSES(state, value) {
      state.courses = value;
      state.coursesLoadedOnce = !!value;
    },
    RESET_COURSES(state) {
      state.courses = null;
    },
    RELOAD_COURSES(state, value) {
      state.reloadCourses = value;
    },
    UPDATE_PROGRESS(state, value) {
      const course = state.courses.find((item) => {
        return item.id === value.courseId;
      });

      course.percent_completed = value.courseProgress;
    },
    UPDATE_CURRENT_LESSON(state, payload) {
      payload.course.current_lesson = payload.currentLessonId;
    },
    END_SALES(state) {
      state.courses.forEach((item) => (item.sales_config = null));
    },
    SET_IOS_LOCKED_PARAMS(state, { appProductId, iosLockedParams }) {
      const index = state.courses.findIndex((item) => item.app_product_id === appProductId);
      if (index !== -1) {
        // Creating a new object with the updated property
        const updatedCourse = { ...state.courses[index], ios_locked_params: iosLockedParams };
        // Replacing the old item with the updated one in the array
        state.courses.splice(index, 1, updatedCourse);
      }
    },
  },
};
