import appClient from '@shared/api/appClient';
import appLibrary from '@shared/base/appLibrary';

export default {
  namespaced: true,
  namespace: 'photoReview',
  state: {
    opened: false,
    loading: true,
    loadingPhoto: false,
    loadingReviews: false,
    progress: 0,
    generating: false,
    reviews: [],
    results: null,
    currentImage: null,
    fullImage: null,
    showFullImage: false,
    currentPage: null,
    lastPage: null,
    error: false,
  },
  actions: {
    open({ commit }) {
      commit('SET_OPENED', true);
    },
    close({ commit }) {
      commit('SET_OPENED', false);
    },
    getPhotoReviews({ commit }, payload) {
      commit('SET_LOADING_REVIEWS', true);

      appClient.getPhotoReviews(payload.page).then(({ data }) => {
        commit('SET_PHOTO_REVIEWS', {
          reviews: data.data,
          update: payload.update,
          currentPage: data.meta.current_page,
          lastPage: data.meta.last_page,
        });
        commit('SET_LOADING', false);
        commit('SET_LOADING_REVIEWS', false);
      });
    },
    loadReview({ commit }, id) {
      commit('SET_LOADING_PHOTO', true);

      appClient
        .getPhotoReview(id)
        .then(({ data }) => {
          commit('SET_CURRENT_IMAGE', data.thumbnail_url);
          commit('SET_FULL_IMAGE', data.image_url);
          setTimeout(() => {
            commit('SET_RESULTS', data.review);
            commit('SET_LOADING_PHOTO', false);
          }, 2000);
        })
        .catch(() => {
          commit('SET_ERROR', true);
        });
    },
    async createReview({ commit, dispatch, rootState }, payload) {
      const filename = `${rootState.user.user.contact_id}_${Date.now()}.jpg`;

      commit('RESET_REVIEW');
      commit('SET_LOADING_PHOTO', payload.isSamplePhoto);
      commit('SET_GENERATING', true);
      commit('SET_PROGRESS', 1);
      commit('SET_CURRENT_IMAGE', payload.thumbnail);
      commit('SET_FULL_IMAGE', payload.thumbnail);

      setTimeout(() => commit('SET_PROGRESS', 10), 1000);

      try {
        const { data } = await appClient.createAiImageReview(filename);

        setTimeout(() => commit('SET_PROGRESS', 50), 2000);

        await appClient.uploadImageReview(data.url, payload.file);

        setTimeout(() => commit('SET_PROGRESS', 81), 3000);
        setTimeout(() => commit('SET_PROGRESS', 99), 6000);

        const response = await appClient.generateReview(data.image_review_id, payload.isSamplePhoto);

        commit('SET_PROGRESS', 100);
        commit('SET_RESULTS', response.data);
        commit('SET_GENERATING', false);
        commit('SET_LOADING_PHOTO', false);

        dispatch('addReview', {
          id: data.image_review_id,
          thumbnail_url: payload.thumbnail,
        });
      } catch (error) {
        appLibrary.methods.trackEvent('photo_review_failed', { error: error });
        commit('SET_ERROR', true);
      }
    },
    addReview({ commit }, review) {
      commit('ADD_REVIEW', review);
    },
    setCurrentImage({ commit }, image) {
      commit('SET_CURRENT_IMAGE', image);
    },
    showFullImage({ commit }) {
      commit('SET_SHOW_FULL_IMAGE');
    },
    resetReview({ commit }) {
      commit('RESET_REVIEW');
    },
  },
  mutations: {
    SET_OPENED(state, opened) {
      state.opened = opened;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_LOADING_REVIEWS(state, value) {
      state.loadingReviews = value;
    },
    SET_PROGRESS(state, progress) {
      state.progress = progress;
    },
    SET_GENERATING(state, value) {
      state.generating = value;
    },
    SET_CURRENT_IMAGE(state, image) {
      state.currentImage = image;
    },
    SET_FULL_IMAGE(state, image) {
      state.fullImage = image;
    },
    SET_SHOW_FULL_IMAGE(state) {
      state.showFullImage = !state.showFullImage;
    },
    SET_PHOTO_REVIEWS(state, value) {
      if (value.update) {
        state.reviews.push(...value.reviews);
      } else {
        state.reviews = value.reviews;
      }
      state.currentPage = value.currentPage;
      state.lastPage = value.lastPage;
      state.loading = false;
    },
    ADD_REVIEW(state, review) {
      state.reviews.unshift(review);
    },
    SET_RESULTS(state, review) {
      state.results = review;
    },
    RESET_REVIEW(state) {
      state.progress = 0;
      state.results = null;
      state.currentImage = null;
      state.generating = false;
      state.loadingPhoto = false;
    },
    SET_LOADING_PHOTO(state, loading) {
      state.loadingPhoto = loading;
    },
    SET_ERROR(state, value) {
      state.error = value;
    },
  },
};
