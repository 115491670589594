<template>
  <div class="comment-rate">
    <transition-expand>
      <div class="comment-rate__nest" v-show="!sent">
        <p class="comment-rate__title">{{ $t('rate.howWouldYouRate') }}</p>

        <div class="comment-rate__stars" :class="{ 'comment-rate__stars--sending': sending }" @mouseleave="resetRate">
          <div
            class="comment-rate__star"
            :class="{ 'comment-rate__star--full': isFull(index + 1) }"
            v-for="(start, index) in 5"
            :key="index"
            @click="setRate(index + 1)"
            @mouseenter="!selected ? (rate = index + 1) : null">
            <img :src="vaporAsset('images/icons/star_outlined.svg')" alt="star" class="empty" />
            <img :src="vaporAsset('images/icons/star_full.svg')" alt="star" class="full" />
          </div>
        </div>

        <div class="comment-rate__legend">
          <p class="comment-rate__legend-text">{{ $t('rate.bad') }}</p>
          <p class="comment-rate__legend-text">{{ $t('rate.great') }}</p>
        </div>

        <app-button class="comment-rate__submit" color="white" :outlined="true" @click="sendRate">{{
          $t('submit')
        }}</app-button>
      </div>
    </transition-expand>

    <transition-expand>
      <div class="comment-rate__success" v-show="sent && showRating">{{ $t('rate.thankYouFeedback') }}</div>
    </transition-expand>
  </div>
</template>

<script>
import TransitionExpand from '@shared/components/utils/TransitionExpand.vue';
import appClient from '@shared/api/appClient';

export default {
  name: 'LessonCommentRate',
  components: {
    TransitionExpand,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showRating: true,
      sending: false,
      sent: false,
      selected: false,
      rate: null,
    };
  },
  methods: {
    setRate(index) {
      this.rate = index;
      this.selected = true;
    },
    resetRate() {
      !this.selected ? (this.rate = null) : null;
    },
    sendRate() {
      this.sending = true;

      appClient.rateReply(this.comment.id, this.rate).then(() => {
        if (this.isMobile) {
          setTimeout(() => (this.sent = true), 1000);
        } else {
          this.sent = true;
        }

        setTimeout(
          () => {
            this.showRating = false;
            this.comment.is_rating_required = false;
          },
          this.isMobile ? 5000 : 4000,
        );
      });
    },
    isFull(index) {
      return index <= this.rate;
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-rate {
  display: inline-block;
  position: relative;
  color: var(--gray-3);
  margin: 10px 0 0 0;
  overflow: hidden;

  @include tablet-portrait {
    margin: 10px 0 0 0;
  }

  &__title {
    margin: 0 0 8px 0;
  }

  &__stars {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px 0;

    &--sending {
      pointer-events: none;
    }
  }

  &__star {
    position: relative;
    height: 30px;
    width: 31px;
    cursor: pointer;

    &:last-child {
      margin: 0;
    }

    @include phone-portrait {
      height: 35px;
      width: 36px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;

      &.full {
        opacity: 0;
      }
    }

    &--full {
      img.empty {
        opacity: 0;
      }
      img.full {
        opacity: 1;
      }
    }
  }

  &__legend {
    display: flex;
    justify-content: space-between;
  }

  &__legend-text {
    margin: 0;
  }

  &__submit.button.button--white {
    font-size: $text-sm;
    line-height: 35px;
    margin: 8px 0 0 0;
    border-color: var(--gray-3);
  }

  &__success {
    color: var(--green);
  }
}
</style>
