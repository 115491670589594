<template>
  <div class="quick-tips-all" :class="{ 'quick-tips-all--mobile': isMobile }" ref="quick-tips">
    <app-container class="quick-tips-all__top">
      <a class="quick-tips-all__back" @click="goBack">
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
        <span>{{ $t('back') }}</span>
      </a>
    </app-container>

    <template v-if="!categoriesLoading">
      <app-container class="quick-tips-all__header" :class="categoryClasses">
        <div class="quick-tips-all__header-bg" v-if="isMobile"></div>
        <app-bullets
          class="quick-tips-all__categories"
          default-id="all"
          prop-id="slug"
          prop-label="name"
          :options="categories"
          :loading="categoriesLoading"
          :value="activeCategory"
          @valueChange="handleCategoryChange($event)"
          @scrollChange="handleCategoryClasses"
          ref="bullets" />
      </app-container>
    </template>

    <template v-else>
      <quick-tips-all-categories-placeholder />
    </template>

    <template v-if="!quickTipsAllLoading">
      <app-container class="quick-tips-all__thumbnail-container">
        <div
          class="quick-tips-all__thumbnail"
          v-for="video in allQuickTips"
          ref="quick-tips-thumbnail"
          :key="video.id"
          @click="handleVideoClick(video)">
          <img :src="video.thumbnail" alt="Video Thumbnail" />
          <div class="quick-tips-all__thumbnail-views_count">
            <font-awesome-icon :icon="['far', 'play']" />
            <span>{{ compactNumberFormatter(video.views_count, { formatBreakpoint: 10_000, space: true }) }}</span>
          </div>
        </div>
      </app-container>
    </template>
    <template v-else>
      <quick-tips-all-thumbnails-placeholder />
    </template>
  </div>
</template>

<script>
import AppContainer from '@course-portal/components/layout/AppContainer.vue';
import AppBullets from '@course-portal/components/ui/AppBullets.vue';
import QuickTipsAllThumbnailsPlaceholder from './QuickTipsAllThumbnailsPlaceholder.vue';
import QuickTipsAllCategoriesPlaceholder from './QuickTipsAllCategoriesPlaceholder.vue';
import { mapGetters, mapState } from 'vuex';
import NProgress from 'nprogress';

export default {
  components: {
    AppContainer,
    AppBullets,
    QuickTipsAllThumbnailsPlaceholder,
    QuickTipsAllCategoriesPlaceholder,
  },
  data() {
    return {
      page: 1,
      perPage: 12,
      categoryClasses: null,
      observer: undefined,
    };
  },
  computed: {
    ...mapState('quickTips', [
      'categories',
      'categoriesLoading',
      'quickTipsAllLoading',
      'noMoreQuickTips',
      'allQuickTips',
    ]),
    ...mapGetters('quickTips', ['activeCategory']),
    fetchFilters() {
      return {
        per_page: this.perPage,
        page: this.page,
      };
    },
  },
  watch: {
    quickTipsAllLoading() {
      if (!this.quickTipsAllLoading && this.allQuickTips.length) {
        if (this.observer) {
          this.observer = null;
        }
        this.$nextTick(() => {
          this.infiniteScroll();
        });
      }
    },
  },
  methods: {
    goBack() {
      this.$store.dispatch('quickTips/setCategory', null);
      this.$router.push({ name: 'quick-tips' });
    },
    handleCategoryChange(slug) {
      this.page = 1;
      this.$store.dispatch('quickTips/setCategory', slug);
      this.$store.dispatch('quickTips/resetAllQuickTips');
      this.$store.dispatch('quickTips/toggleQuickTipsAllLoading', true);
      this.$store
        .dispatch('quickTips/fetchAllQuickTips', {
          filters: this.fetchFilters,
          includes: 'include=watchedProgressCount',
        })
        .finally(() => {
          this.$store.dispatch('quickTips/toggleQuickTipsAllLoading', false);
        });

      this.trackCategoryChange();
    },
    handleCategoryClasses(classes) {
      this.categoryClasses = classes;
    },
    handleVideoClick(video) {
      this.trackVideoVisitFromCategory(video);

      this.$router.push({ name: 'quick-tips', params: { id: video.id } });
    },
    loadMoreQuickTips() {
      return this.$store.dispatch('quickTips/fetchAllQuickTips', {
        filters: this.fetchFilters,
        includes: 'include=watchedProgressCount',
      });
    },
    infiniteScroll() {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((elem) => {
            if (elem.isIntersecting && !this.noMoreQuickTips) {
              this.page++;
              this.loadMoreQuickTips().then((loadedMoreVideos) => {
                if (!loadedMoreVideos) return;
                this.$nextTick(() => {
                  if (this.observer) {
                    this.observer.disconnect();
                    this.observer.observe(
                      this.$refs['quick-tips-thumbnail'][this.$refs['quick-tips-thumbnail'].length - 1],
                    );
                  }
                });
              });
            }
          });
        },
        {
          root: null,
          threshold: 1,
        },
      );
      this.$nextTick(() => {
        this.observer.observe(this.$refs['quick-tips-thumbnail'][this.$refs['quick-tips-thumbnail'].length - 1]);
      });
    },
    getCategoriesScrollWidth() {
      this.categoriesScrollWidth = this.$refs.bullets?.$el.scrollWidth;
    },
    getCategoryBySlug(slug) {
      return this.categories.find((c) => c.slug === slug);
    },
    async init() {
      await this.$store.dispatch('quickTips/fetchCategories');
      this.$store.dispatch('quickTips/toggleQuickTipsAllLoading', true);
      await this.$store.dispatch('quickTips/resetAllQuickTips');
      await this.$store
        .dispatch('quickTips/fetchAllQuickTips', {
          filters: this.fetchFilters,
          includes: 'include=watchedProgressCount',
        })
        .finally(() => {
          this.$store.dispatch('quickTips/toggleQuickTipsAllLoading', false);
        });
      this.getCategoriesScrollWidth();
      this.afterOrientationChange(this.getCategoriesScrollWidth);
      window.addEventListener('resize', this.getCategoriesScrollWidth);
    },
    trackCategoryChange() {
      const category = this.getCategoryBySlug(this.activeCategory);
      this.trackEvent('button_clicked', {
        button: 'quick_tips_grid_view_category_selected',
        category_id: category.id,
        category_name: category.name,
      });
    },
    trackVideoVisitFromCategory(video) {
      const category = this.getCategoryBySlug(this.activeCategory);
      this.trackEvent('button_clicked', {
        button: 'quick_tips_grid_view_tip_selected',
        category_id: category.id,
        category_name: category.name,
        quick_tip_id: video.id,
        quick_tip_title: video.title,
      });
    },
  },
  mounted() {
    this.init().finally(() => {
      NProgress.done();
    });
  },
};
</script>
<style lang="scss" scoped>
.quick-tips-all {
  position: relative;
  padding: 0 0 32px 0;

  &--mobile {
    .quick-tips-all__header {
      position: sticky;
      top: $app-header-height;
      z-index: 1;
    }
  }

  &__top {
    padding: 32px 10px 0 10px;

    @include tablet-landscape {
      padding: 32px 0 0 0;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 8px;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    z-index: 2;
    cursor: pointer;

    @include tablet-portrait {
      font-size: 14px;
    }

    span {
      line-height: 11px;
    }
  }

  &__header {
    position: relative;
    padding: 0;

    &.dim-left {
      &:before {
        opacity: 1;
      }
    }

    &.dim-right {
      &:after {
        opacity: 1;
      }
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 50px;
      height: 100%;
      opacity: 0;
      z-index: 2;
      pointer-events: none;
      transition: opacity 0.2s ease-in-out;
    }
    &:before {
      left: 0;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.6), transparent);
    }
    &:after {
      right: 0;
      background: linear-gradient(to left, rgba(0, 0, 0, 0.6), transparent);
    }
  }

  &__header-bg {
    position: absolute;
    bottom: -32px;
    left: 0;
    width: 100%;
    height: 177px;
    background: linear-gradient(180deg, #000 calc(100% - 32px), rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
  }

  &__categories {
    position: relative;
    padding: 16px 10px 0 10px;
    margin: 8px 0 16px 0;
    background: #000;

    @include tablet-landscape {
      padding: 0 0 16px 0;
      margin: 32px 0 0 0;
    }
  }

  &__thumbnail-container {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px;

    @include tablet-portrait {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 8px;
    }
  }

  &__thumbnail {
    position: relative;
    cursor: pointer;

    img {
      display: block;
      width: 100%;
      height: 100%;
      aspect-ratio: 3/5;
    }

    &-views_count {
      position: absolute;
      display: flex;
      align-items: center;
      gap: 6px;
      bottom: 6px;
      left: 6px;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: white;
      border-radius: 0 0 0 4px;

      @include tablet-portrait {
        font-size: 16px;
      }

      @include tablet-portrait(portrait) {
        bottom: 8px;
        left: 10px;
        line-height: 25px;
      }

      @include tablet-landscape {
        bottom: 8px;
        left: 8px;
        line-height: 25px;
      }

      span {
        line-height: 1px;
      }
    }
  }
}
</style>
