<template>
  <div class="toast" :class="`toast--${badge}`">
    <div class="toast__icon">
      <img :src="vaporAsset(`images/icons/${badge}-badge.svg`)" alt="icon" />
    </div>

    <div class="toast__body">
      <p class="toast__title">{{ $t('notifications.congratulations') }}</p>
      <p class="toast__description">
        {{ youveEarned }} <span>{{ badge }}</span> {{ status }}
      </p>
    </div>

    <div class="toast__close" @click.stop="$emit('close-toast')"><span>&times;</span></div>
  </div>
</template>

<script>
import appLibrary from '@shared/base/appLibrary';
import i18n from '@shared/i18n';

export default {
  name: 'NotificationBadgeUnlocked',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  mixins: [appLibrary],
  computed: {
    badge() {
      return this.notification.data.badge.name;
    },
    youveEarned() {
      return i18n.global.t('notifications.youveEarned');
    },
    status() {
      return i18n.global.t('notifications.status');
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  &--Intermediate {
    .toast__description {
      span {
        color: var(--green-light);
      }
    }
  }
  &--Advanced {
    .toast__description {
      span {
        color: var(--purple);
      }
    }
  }
  &--Master {
    .toast__description {
      span {
        color: var(--gold);
      }
    }
  }

  &__icon {
    position: relative;
    height: 50px;
    width: 50px;
    min-width: 50px;
    margin: 0 10px 0 0;

    @include tablet-portrait {
      height: 56px;
      width: 56px;
      min-width: 56px;
    }
  }

  &__description {
    font-weight: $semiBold;
  }
}
</style>
