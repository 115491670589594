<template>
  <div class="page-not-found">
    <span class="page-not-found__bg">404</span>

    <p class="page-not-found__title" v-html="$t('notFound.oops')"></p>

    <router-link :to="{ name: 'home' }" class="page-not-found__btn" v-if="!guest">
      <span>{{ $t('notFound.takeMeTo') }}</span>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('user', {
      guest: (state) => state.guest,
    }),
  },
};
</script>

<style lang="scss" scoped>
.page-not-found {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: calc(100vh - #{$app-header-height});
  text-align: center;
  padding: 0 26px;
  overflow: hidden;

  &__bg {
    position: absolute;
    left: 50%;
    top: 17%;
    font-size: 30vh;
    line-height: 1;
    font-weight: $bold;
    color: #0a0a0a;
    transform: translateX(-50%);

    @include phone-landscape {
      top: 0;
      font-size: 80vh;
    }
    @include tablet-portrait(portrait) {
      top: 10%;
      font-size: 45vh;
    }
    @include tablet-landscape {
      top: 0;
      font-size: 60vh;
    }
  }

  &__title {
    position: relative;
    font-size: 28px;
    font-weight: $semiBold;
    margin: 0 0 40px 0;
  }

  &__btn {
    display: block;
    position: relative;
    width: 338px;
    max-width: 100%;
    font-size: 16px;
    line-height: 50px;
    font-weight: $semiBold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    border: none;
    border-radius: 7px;
    outline: none;
    background: linear-gradient(135deg, #ed1c24 -20.63%, #9b2686 125.74%);

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      border-radius: inherit;
    }

    &:before {
      background: var(--black);
    }

    &:after {
      background: linear-gradient(180deg, #ed1c24 0%, #9b2686 100%);
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
    }

    &:hover {
      &:after {
        opacity: 0.2;
      }
    }

    span {
      position: relative;
      z-index: 10;
    }
  }
}
</style>
