<template>
  <div class="activity-item" @click="goToLesson">
    <div class="activity-item__icon">
      <img
        :src="activity.data.engagement_reminder.lesson_thumbnail"
        alt="icon"
        class="activity-item__icon-img"
        @load="loaded = true"
        v-show="loaded" />
      <div class="activity-item__icon-loading" @load="loaded = true" v-show="!loaded"></div>
    </div>

    <div class="activity-item__content">
      <p class="activity-item__description">
        <strong>{{ activity.data.engagement_reminder.title }}</strong
        ><br />
        <span class="activity-item__description--content">{{ activity.data.engagement_reminder.body }}!</span>
      </p>

      <p class="activity-item__time">{{ timeago(activity.created_at) }}</p>
    </div>

    <div class="activity-item__indicator" @click="markAsRead" v-if="!activity.is_read"></div>
  </div>
</template>

<script>
export default {
  name: 'ActivityCommentReply',
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    goToLesson() {
      const lessonLink = this.activity.data.engagement_reminder.link;
      window.location.href = lessonLink;
      this.$emit('close-toast');
      this.trackEvent('button_clicked', { button: 'activity_engagement_reminder_clicked' });
    },
    markAsRead() {
      this.$store.dispatch('profile/readActivity', {
        id: this.activity.id,
        read: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-item {
  display: flex;
  position: relative;
  padding: 12px 34px 12px 10px;
  margin-bottom: 6px;
  border-radius: 6px;
  align-items: center;

  @include tablet-portrait {
    padding: 12px 34px 12px 15px;
  }
  @include desktop-xs {
    &:hover {
      background-color: var(--gray-8);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
  &:active {
    background-color: var(--gray-8);
  }

  &__content {
    flex: 2;
  }

  &__icon {
    position: relative;
    height: 42px;
    width: 42px;
    margin: 0 15px 0 0;

    @include phone-portrait {
      height: 52px;
      width: 52px;
      margin: 0 20px 0 0;
    }
  }

  &__icon-img {
    display: block;
    height: 100%;
    width: auto;
    object-fit: cover;
    border-radius: 50%;
  }

  &__icon-loading {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: var(--gray-6);
  }

  &__description {
    font-size: $text-sm;
    margin: 0 0 2px 0;

    @include phone-portrait {
      font-size: $text-md;
    }

    strong {
      font-weight: $semiBold;
    }

    &--content {
      color: var(--gray-3);
    }
  }

  &__time {
    font-size: 12px;
    color: var(--gray-3);
    margin: 0;

    @include tablet-portrait {
      font-size: $text-sm;
    }
  }

  &__indicator {
    position: absolute;
    top: 50%;
    right: 10px;
    height: 9px;
    min-width: 9px;
    margin: -4.5px 0 0 15px;
    border-radius: 50%;
    background: var(--red);
    cursor: pointer;

    @include tablet-portrait {
      right: 15px;
    }
  }
}
</style>
