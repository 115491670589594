<template>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>
<style lang="scss">
.loader {
  span {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 3px;
    border-radius: 50%;
    background: var(--gray-6);

    &:nth-child(1) {
      animation: bounce 1s ease-in-out infinite;
    }
    &:nth-child(2) {
      animation: bounce 1s ease-in-out 0.33s infinite;
    }
    &:nth-child(3) {
      animation: bounce 1s ease-in-out 0.66s infinite;
    }
  }

  @keyframes bounce {
    0%,
    75%,
    100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-8px);
    }
  }
}
</style>
