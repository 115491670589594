<template>
  <div class="toast" :class="`toast--${notification.type}`" @click="showAchievement">
    <img :src="notification.data.achievement.image" alt="icon" class="toast__img" />

    <div class="toast__body">
      <p class="toast__title">{{ title }}</p>
      <p class="toast__description">{{ notification.data.achievement.name }}</p>
    </div>

    <div class="toast__close" @click.stop="$emit('close-toast')"><span>&times;</span></div>
  </div>
</template>

<script>
import router from '@course-portal/routes';
import store from '@course-portal/store';
import i18n from '@shared/i18n';

export default {
  name: 'NotificationAchievementUnlocked',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return i18n.global.t('notifications.achievementUnlocked');
    },
  },
  methods: {
    showAchievement() {
      this.$emit('close-toast');
      store.dispatch('profile/setAchievementHighlighted', this.notification);
      router.push({ name: 'profile' });
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  cursor: pointer;

  &__img {
    height: 50px;
    width: 50px;
    min-width: 50px;
    margin: 0 10px 0 0;

    @include tablet-portrait {
      height: 56px;
      width: 56px;
      min-width: 56px;
    }
  }

  &__description {
    font-weight: $semiBold;
  }
}
</style>
