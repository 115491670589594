<template>
  <div class="notes">
    <div
      class="notes__item notes__item--description"
      :class="{ 'notes__item--highlighted': highlighted }"
      v-html="preventWidows(description)"
      v-if="description"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LessonNotes',
  data() {
    return {
      highlighted: false,
    };
  },
  computed: {
    ...mapState('layout', {
      notesHighlighted: (state) => state.notesHighlighted,
    }),
    ...mapState('lesson', {
      lessonId: (state) => state.lesson.id,
      description: (state) => state.lesson.notes.notes,
      videoId: (state) => state.lesson.video_id,
    }),
    transcriptTitle() {
      return this.summary ? this.$t('lesson.transcript') : this.$t('lesson.summaryNotes');
    },
  },
  watch: {
    notesHighlighted: {
      handler(val) {
        if (val) {
          setTimeout(() => (this.highlighted = true), 600);
          setTimeout(() => this.$store.dispatch(`layout/setNotesHighlighted`, false), 6000);
        } else {
          this.highlighted = false;
          this.$store.dispatch(`layout/setNotesHighlighted`, false);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.notes {
  padding: 0 0 60px 0;

  &__heading {
    font-size: 19px;
    font-weight: $semiBold;
    color: var(--gray-1);
    margin: 0 0 20px 0;

    @include tablet-portrait {
      margin: 30px 0;

      &:first-child {
        margin: 0 0 30px 0;
      }
    }
  }

  &__item {
    position: relative;
    line-height: 1.4;
    color: var(--gray-2);
    margin: 0 0 32px 0;

    &:last-child {
      margin-bottom: 0;
    }

    &--description {
      position: relative;
      margin: 0 0 56px 0;

      &::before {
        content: '';
        position: absolute;
        top: -16px;
        bottom: -16px;
        left: -10px;
        right: -10px;
        background-color: rgba(var(--green-light-rgb), 0.2);
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease-in-out;
        z-index: -1;

        @include tablet-portrait {
          left: -16px;
          right: -16px;
          border-radius: 8px;
        }
      }
    }

    &--highlighted {
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    ::v-deep(p) {
      margin: 0 0 16px 0;

      &:last-child {
        margin: 0;
      }

      a {
        text-decoration: underline;
      }
    }
  }

  &__timestamp {
    margin-right: 6px;
    // Displays numbers with equal sizing, proportional and spacing
    // for clean formatting in tabular data contexts
    font-variant-numeric: tabular-nums;
    color: var(--red);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__text {
    display: inline;
    margin: 0;

    ::v-deep(ol) {
      margin: 5px 0 0 0;
    }
    ::v-deep(ul) {
      margin: 5px 0 0 0;
    }
  }
}
</style>
