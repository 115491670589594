<template>
  <div class="comments" ref="comments">
    <lesson-comments-placeholder v-if="isLoading"></lesson-comments-placeholder>

    <template v-if="!isLoading && !isNoComments">
      <div
        class="comments__scroll-buttons-wrapper"
        :class="{
          'comments__scroll-buttons-wrapper--show': !isCommenterVisible,
          'comments__scroll-buttons-wrapper--with-top-banner': banner && !isPhone,
          'comments__scroll-buttons-wrapper--hidden': isCommenterVisible,
        }">
        <div class="comments__scroll-buttons-nest">
          <app-button class="comments__add-new-btn" color="gray-6" :large="true" v-scroll-to="scrollToCommentInput">
            <font-awesome-icon :icon="['fas', 'message']" class="comments__add-new-icon" />
          </app-button>
          <app-button class="comments__back-to-video-btn" color="gray-6" :large="true" v-scroll-to="scrollToTop">
            <font-awesome-icon :icon="['fa', 'arrow-up']" class="comments__back-to-video-icon" />
          </app-button>
        </div>
      </div>

      <div class="comments__comment-box" ref="commentBox" v-if="!courseLocked && !isCourseICA">
        <lesson-commenter minimal :focus="isCommentFocus" @resetFocus="resetFocus"></lesson-commenter>
      </div>

      <transition-group name="comment">
        <lesson-comment v-for="comment in comments" :key="comment.id" :comment="comment"></lesson-comment>
      </transition-group>

      <transition name="appear">
        <div class="comments__loader" v-if="commentsLoading">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </transition>

      <transition name="appear">
        <div class="comments__no-more" v-if="noMoreComments">
          {{ $t('comments.noMoreComments') }}
        </div>
      </transition>
    </template>

    <template v-if="isNoComments">
      <div class="comments__comment-box" ref="commentBox" v-if="!courseLocked && !isCourseICA">
        <lesson-commenter minimal @resetFocus="resetFocus"></lesson-commenter>
      </div>

      <div class="comments__empty">
        <font-awesome-icon :icon="['fas', 'message']" class="comments__empty-icon" />
        <h5 class="comments__empty-title" v-html="$t('comments.noMoreCommentsForThisLesson')"></h5>
        <p class="comments__empty-text">{{ $t('comments.beFirst') }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import LessonComment from './LessonComment.vue';
import LessonCommenter from './LessonCommenter.vue';
import LessonCommentsPlaceholder from './LessonCommentsPlaceholder.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'LessonComments',
  components: {
    LessonComment,
    LessonCommenter,
    LessonCommentsPlaceholder,
  },
  data() {
    return {
      isCommenterVisible: true,
      isAddNewComment: false,
      isCommentFocus: false,
      isCommentDeleted: false,
      page: 1,
    };
  },
  computed: {
    ...mapGetters('course', ['courseLocked', 'courseId']),
    ...mapState('layout', {
      headerHeight: (state) => state.headerHeight,
      tabsbarHeight: (state) => state.tabsbarHeight,
      bannerHeight: (state) => state.bannerHeight,
      isLandscape: (state) => state.isLandscape,
    }),
    ...mapState('comments', {
      comments: (state) => state.comments,
      commentsTotal: (state) => state.commentsTotal,
      commentsNextPage: (state) => state.commentsNextPage,
      commentsPrevPage: (state) => state.commentsPrevPage,
      commentsLastPage: (state) => state.commentsLastPage,
      commentsLoading: (state) => state.commentsLoading,
      commentFocus: (state) => state.commentFocus,
    }),
    ...mapState('course', {
      course: (state) => state.course,
    }),
    ...mapState('lesson', {
      lesson: (state) => state.lesson,
      activeTab: (state) => state.activeTab,
    }),
    ...mapState('banner', {
      banner: (state) => state.banner,
    }),
    ...mapState('user', {
      guest: (state) => state.guest,
    }),
    isLoading() {
      return this.commentsLoading && !this.comments.length && !this.commentsTotal;
    },
    isCourseICA() {
      return this.courseId && this.courseId == 15;
    },
    isNoComments() {
      return !this.isLoading && !this.comments.length;
    },
    getCommentsAction() {
      return this.guest ? 'comments/setSharedComments' : 'comments/setComments';
    },
    scrollToCommentInput() {
      const offset = this.isMobile
        ? -(this.headerHeight + this.bannerHeight + (this.isLandscape && this.isIPad ? null : this.tabsbarHeight))
        : -(this.headerHeight + this.bannerHeight);

      return {
        el: '.comments__comment-box',
        duration: 400,
        offset: offset,
        onDone: () => {
          this.isCommentFocus = true;
        },
      };
    },
    scrollToTop() {
      return {
        el: 'body',
        duration: 400,
        offset: 0,
      };
    },
    nextPage() {
      if (this.commentsNextPage) {
        return this.commentsNextPage.match(/\d+(?!.*\d)/)[0];
      }
    },
    canLoadComments() {
      return (
        this.page < this.commentsLastPage && this.commentsNextPage && !this.commentsLoading && this.activeTab === 2
      );
    },
    noMoreComments() {
      return !this.commentsNextPage && this.commentsPrevPage;
    },
  },
  watch: {
    isLoading() {
      this.$nextTick(() => {
        if (this.$refs.commentBox) {
          this.checkCommentBoxVisible();
        }
      });
    },
  },
  methods: {
    init() {
      this.$store
        .dispatch(this.getCommentsAction, {
          lessonId: this.$route.params.id,
          page: this.page,
          commentId: this.$route.query.comment_id,
        })
        .then(() => {
          window.addEventListener('scroll', this.infiniteScroll);
        })
        .catch((error) => {
          this.handleCommentsError(error);
        });
    },
    resetFocus() {
      this.isCommentFocus = false;
    },
    checkCommentBoxVisible() {
      const commentBoxElem = this.$refs.commentBox;
      const offset = -(this.isPhone
        ? this.headerHeight + this.tabsbarHeight + this.bannerHeight
        : this.headerHeight + this.bannerHeight);

      const observer = new IntersectionObserver(
        (entries) => {
          this.isCommenterVisible =
            window.scrollY > commentBoxElem.getBoundingClientRect().top ? entries[0].isIntersecting : true;
        },
        {
          rootMargin: `${offset}px`,
          threshold: [0],
        },
      );

      observer.observe(commentBoxElem);
    },
    infiniteScroll() {
      const bottomOffset = 5;
      const bottomOfWindow = window.innerHeight + window.scrollY >= document.body.offsetHeight - bottomOffset;

      if (bottomOfWindow && this.canLoadComments) {
        this.$store.dispatch(this.getCommentsAction, {
          lessonId: this.$route.params.id,
          page: this.nextPage,
          commentId: this.isCommentDeleted || this.$route.query.comment_id,
        });
      }
    },
    handleCommentsError(error) {
      if (!error) {
        return;
      }

      this.isCommentDeleted = true;

      this.$store.dispatch('notifications/showToast', {
        item: {
          type: 'error',
          content: {
            message: error.data.message,
          },
        },
        isMobile: this.isMobile,
      });

      // If comment does not exist - load comments with default parameters
      this.$store.dispatch(this.getCommentsAction, {
        lessonId: this.$route.params.id,
        page: this.page,
        commentId: null,
      });
    },
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.infiniteScroll);
    this.$store.dispatch('comments/resetComments');
  },
};
</script>

<style lang="scss" scoped>
.comments {
  padding-bottom: 50px;

  &__comment-box {
    padding: 32px 0 0 0;
    margin: -32px 0 32px 0;

    @include tablet-portrait {
      margin: -32px 0 40px 0;
    }
  }

  &__scroll-buttons-wrapper {
    position: sticky;
    left: 0;
    top: 119px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    z-index: 10;

    &--show {
      opacity: 1;
      visibility: visible;
    }

    &--with-top-banner {
      top: 119px + #{$app-banner-height};
    }

    &--hidden {
      @include phone-landscape(landscape) {
        opacity: 0;
        visibility: hidden;
      }
    }

    @include iphone-11-pro-max(landscape) {
      padding: 0 20px;
    }
    @include iphone-12-pro-max(landscape) {
      padding: 0 20px;
    }
    @include phone-landscape(landscape) {
      top: 61px;
    }
    @include tablet-portrait(portrait) {
      position: sticky;
      top: calc($app-header-height + 14px);
      bottom: auto;

      &--with-top-banner {
        top: calc(119px + #{$app-banner-height});
      }
    }
    @include tablet-landscape {
      position: fixed;
      top: auto;
      bottom: 0;
      left: 14px;
      width: calc(100% - 364px);
      padding: 0 20px;
    }
    @include desktop-xs {
      width: calc(100% - 414px);
    }
    @include desktop-sm {
      width: calc(100% - 474px);
    }
    @include desktop-lg {
      width: calc(100% - 514px);
    }
    @include ipad-pro-129(portrait) {
      top: 127px;
      bottom: auto;
      left: 0;
      width: 100%;
      padding: 0 20px;
      z-index: 8000;

      &--with-top-banner {
        top: calc(115px + #{$app-banner-height});
      }
    }

    .button {
      width: 54px;
      font-size: $text-lg;
      line-height: 54px;
      padding: 0 0 0 0;
      border-radius: 50%;
    }
  }
  &__add-new-icon {
    vertical-align: middle;
  }
  &__back-to-video-icon {
    vertical-align: middle;
  }
  &__scroll-buttons-nest {
    position: relative;

    @include tablet-landscape {
      margin: 0 auto;
      max-width: 900px;
    }
    @include ipad-pro-129(portrait) {
      max-width: none;
    }
  }
  &__add-new-btn {
    position: absolute;
    top: 0;
    right: 60px;

    @include tablet-portrait(portrait) {
      bottom: auto;
      top: 0;
    }
    @include tablet-landscape {
      top: auto;
      bottom: 30px;
    }
    @include ipad-pro-129(portrait) {
      bottom: auto;
      top: 0;
    }
  }
  &__back-to-video-btn {
    position: absolute;
    top: 0;
    right: 0;

    @include tablet-portrait(portrait) {
      bottom: auto;
      top: 0;
    }
    @include tablet-landscape {
      top: auto;
      bottom: 30px;
    }
    @include ipad-pro-129(portrait) {
      bottom: auto;
      top: 0;
    }
  }

  &__loader {
    position: relative;
    text-align: center;
    margin: 20px 0 0 0;
    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 0 3px;
      border-radius: 50%;
      background: var(--gray-1);

      &:nth-child(1) {
        animation: bounce 1s ease-in-out infinite;
      }
      &:nth-child(2) {
        animation: bounce 1s ease-in-out 0.33s infinite;
      }
      &:nth-child(3) {
        animation: bounce 1s ease-in-out 0.66s infinite;
      }
    }
  }

  &__no-more {
    position: relative;
    text-align: center;
    color: var(--gray-4);
    margin: 20px 0 0 0;
  }

  @keyframes bounce {
    0%,
    75%,
    100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-8px);
    }
  }

  &__empty {
    text-align: center;
    max-width: 400px;
    margin: 30px auto 0 auto;
  }
  &__empty-icon {
    font-size: 32px;
    color: var(--gray-3);
    margin: 0 0 20px 0;
  }
  &__empty-title {
    font-size: $header-xs;
    margin: 0 0 10px 0;
  }
  &__empty-text {
    color: var(--gray-3);
    margin: 0;
  }
}
</style>
