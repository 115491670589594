<template>
  <div class="description" v-html="description"></div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LessonDescription',
  computed: {
    ...mapState('lesson', {
      description: (state) => state.lesson.description,
    }),
  },
};
</script>

<style lang="scss" scoped>
.description {
  padding: 0 0 60px 0;

  ::v-deep(.download-ebook) {
    display: table;
    line-height: 38px;
    font-weight: $semiBold;
    color: var(--gray-1);
    padding: 0 20px;
    border-radius: 5px;
    background: linear-gradient(180deg, var(--red-gradient));
    background-position: 50% 50%;
    background-size: 100% 150%;
    transition: background-position 0.2s ease-in-out;

    &:hover {
      background-position: 50% 0%;
    }
  }

  ::v-deep(p) {
    color: var(--gray-3);
    margin: 0 0 30px 0;

    &:first-child {
      color: var(--gray-1);
    }

    strong {
      color: var(--gray-1);
    }
  }
}
</style>
