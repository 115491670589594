<template>
  <div class="profile-public-ph">
    <div class="profile-public-ph__top">
      <div class="profile-public-ph__item profile-public-ph__avatar"></div>

      <div class="profile-public-ph__item profile-public-ph__user-name"></div>

      <div class="profile-public-ph__item profile-public-ph__badge"></div>
    </div>

    <div class="profile-public-ph__item profile-public-ph__nest"></div>
  </div>
</template>

<script>
export default {
  name: 'ProfilePublicPlaceholder',
};
</script>

<style lang="scss" scoped>
.profile-public-ph {
  &__top {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 50px 0;
  }

  &__item {
    position: relative;
    border-radius: 5px;
    background: var(--gray-6);
    animation: shimmer 1s ease-in-out infinite;
    animation-direction: alternate;

    &--hidden {
      animation: none;
    }
  }

  &__avatar {
    position: relative;
    height: 100px;
    width: 100px;
    margin-bottom: 13px;
    border-radius: 50%;

    @include tablet-portrait {
      height: 142px;
      width: 142px;
    }
    @include iphone-11(landscape) {
      height: 100px;
      width: 100px;
    }
    @include iphone-11-pro(landscape) {
      height: 100px;
      width: 100px;
    }
    @include iphone-11-pro-max(landscape) {
      height: 100px;
      width: 100px;
    }
    @include iphone-12(landscape) {
      height: 100px;
      width: 100px;
    }
    @include iphone-12-pro-max(landscape) {
      height: 100px;
      width: 100px;
    }
  }

  &__user-name {
    height: 33.8px;
    width: 240px;
  }

  &__badge {
    height: 28px;
    width: 110px;
    margin-top: 7px;
  }

  &__nest {
    height: 250px;
    border-radius: 8px;
  }
}
</style>
