import appClient from '@shared/api/appClient';

export default {
  namespaced: true,
  namespace: 'refer',
  state: {
    shareLink: null,
    paypalAccount: null,
    paypalAccountModel: null,
    referrals: null,
  },
  actions: {
    getReferLink({ commit }) {
      return new Promise((resolve) => {
        appClient.getReferLink().then((response) => {
          if (response) {
            commit('GET_REFER_LINK', response.data);
            resolve();
          }
        });
      });
    },
    getReferrals({ commit }) {
      return new Promise((resolve) => {
        appClient.getReferrals().then((response) => {
          if (response) {
            commit('GET_REFERRALS', response.data);
            resolve();
          }
        });
      });
    },
    loadMoreReferrals({ commit }, page) {
      appClient.getReferrals(page).then((response) => {
        if (response) {
          commit('LOAD_MORE_REFERRALS', response.data);
        }
      });
    },
    getPaypalAccount({ commit }) {
      return new Promise((resolve) => {
        appClient.getPaypalAccount().then((response) => {
          if (response) {
            commit('GET_PAYPAL_ACCOUNT', response.data);
            resolve();
          }
        });
      });
    },
    setPaypalAccountModel({ commit }, value) {
      return new Promise((resolve, reject) => {
        appClient
          .setPaypalAccount(value)
          .then(() => {
            commit('SET_PAYPAL_ACCOUNT_MODEL', value);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
  mutations: {
    GET_REFER_LINK(state, value) {
      state.shareLink = value.links[0];
    },
    GET_REFERRALS(state, value) {
      state.referrals = value;
    },
    LOAD_MORE_REFERRALS(state, value) {
      state.referrals.data.push(...value.data);
      state.referrals.meta.current_page = value.meta.current_page;
    },
    GET_PAYPAL_ACCOUNT(state, value) {
      state.paypalAccount = value.email;
      state.paypalAccountModel = value.email;
    },
    SET_PAYPAL_ACCOUNT_MODEL(state, value) {
      state.paypalAccountModel = value;
    },
  },
};
