<template>
  <div class="home">
    <app-container>
      <div class="home__nest" v-if="courseOnSale">
        <home-card-sale />
      </div>

      <div class="home__nest" v-if="ownedCourses?.length">
        <h2 class="home__title">{{ $t('myCourses') }}</h2>
        <home-owned-courses />
      </div>

      <div class="home__nest" v-if="lockedCourses?.length">
        <h2 class="home__title">{{ $t('home.recommendedForYou') }}</h2>
        <div class="home__courses">
          <home-card-locked v-for="card in lockedCourses" :card="card" :key="card.id" />
        </div>
      </div>
    </app-container>
  </div>
</template>

<script>
import AppContainer from '@course-portal/components/layout/AppContainer.vue';
import HomeCardSale from './HomeCardSale.vue';
import HomeOwnedCourses from './HomeOwnedCourses.vue';
import HomeCardLocked from './HomeCardLocked.vue';
import pwaNotifications from '@course-portal/mixins/pwaNotifications';
import NProgress from 'nprogress';
import { mapGetters, mapState } from 'vuex';
import '@splidejs/vue-splide/css/core';

export default {
  name: 'HomeIndex',
  components: {
    AppContainer,
    HomeCardSale,
    HomeOwnedCourses,
    HomeCardLocked,
  },
  mixins: [pwaNotifications],
  computed: {
    ...mapGetters('courses', ['ownedCourses', 'lockedCourses', 'courseOnSale']),
    ...mapState('courses', {
      courses: (state) => state.courses,
      reloadCourses: (state) => state.reloadCourses,
    }),
    ...mapState('user', {
      user: (state) => state.user,
    }),
    isFirstLogin() {
      return this.$route.query.welcome === 'true';
    },
    /**
     * To be updated once backend is ready
     */
    isPurchase() {
      return this.$route.query.purchase === 'true';
    },
    isHideWelcome() {
      return sessionStorage.getItem('hideWelcome');
    },
  },
  watch: {
    deviceToken: {
      immediate: true,
      handler(val) {
        if (!val || !this.isAppMode) return;
        this._getAppSetup();
      },
    },
    _state(val) {
      this.handlePermissionsState(val);
    },
  },
  methods: {
    throwError() {
      throw new Error('Sentry Error');
    },
    setHideWelcome() {
      sessionStorage.setItem('hideWelcome', 'true');
    },
    handlePermissionsState(state) {
      const allowed = ['authorized', 'ephemeral', 'provisional'].includes(state);

      if (state === 'notDetermined') {
        this.$router.push({ name: 'notifications', params: { step: '1' } });
      } else if (allowed) {
        this._pushDeviceToken();
      } else if (state === 'denied') {
        this._removeDeviceToken();
      }
    },
  },
  mounted() {
    if (this.reloadCourses) {
      Promise.all([
        this.$store.dispatch('courses/resetCourses'),
        this.$store.dispatch('courses/setCourses'),
        this.$store.dispatch('courses/reloadCourses', false),
      ]).finally(() => {
        NProgress.done();
      });
    } else {
      NProgress.done();
    }

    const notificationLink = localStorage.getItem('notificationLink');

    if (notificationLink && notificationLink.trim() !== '') {
      console.log('notificationLink', notificationLink);

      const event = new CustomEvent('handle-local-storage-redirect', {
        detail: { link: notificationLink },
      });
      window.dispatchEvent(event);

      localStorage.removeItem('notificationLink');
    }
  },
  unmounted() {
    this.setHideWelcome();
  },
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  padding: 30px 0 45px 0;

  @include tablet-portrait {
    padding: 32px 0 70px 0;
  }

  &__title {
    font-size: 24px;
    font-weight: $bold;
    padding: 0 0 8px 0;
    margin: 0 0 16px 0;
    border-bottom: 1px solid #343434;

    @include phone-landscape {
      font-size: 34px;
    }
  }

  &__nest {
    position: relative;
    margin: 0 0 32px 0;

    &:last-child {
      margin: 0;
    }
  }

  &__courses {
    position: relative;

    @include phone-landscape {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
      row-gap: 24px;
    }
    @include tablet-landscape {
      column-gap: 32px;
      row-gap: 32px;
    }
  }
}
</style>
