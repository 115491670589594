export default {
  namespaced: true,
  namespace: 'timer',
  state: {
    start: 0,
    remainingSpots: 0,
  },
  actions: {
    startTimer({ commit }) {
      setInterval(() => commit('START_TIMER'), 1000);
    },
    setRemainingSpots({ commit }, payload) {
      commit('SET_REMAINING_SPOTS', payload);
    },
  },
  mutations: {
    START_TIMER(state) {
      state.start = ++state.start;
    },
    SET_REMAINING_SPOTS(state, payload) {
      state.remainingSpots = payload;
    },
  },
};
