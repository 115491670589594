<template>
  <div class="sidebar">
    <transition name="appear">
      <div class="sidebar__nav sidebar__nav--up" v-if="showSidebarUp" @click="moveSidebar(-sidebarScrollAmount)">
        <font-awesome-icon :icon="['fas', 'chevron-up']" />
      </div>
    </transition>

    <div class="sidebar__nest" ref="sidebarNest" @scroll="handleScroll">
      <div class="sidebar__inner" ref="sidebarInner">
        <template v-if="course">
          <sidebar-module
            v-for="module in course.modules"
            :module="module"
            :loadedFromLesson="loadedFromLesson"
            :key="module.id"></sidebar-module>
        </template>
        <div class="sidebar__placeholder" :class="{ 'sidebar__placeholder--in-lesson': isLessonView }" v-else>
          <placeholder v-for="(module, index) in 10" :key="index"></placeholder>
        </div>
      </div>
    </div>

    <transition name="appear">
      <div class="sidebar__nav sidebar__nav--down" v-if="showSidebarDown" @click="moveSidebar(sidebarScrollAmount)">
        <font-awesome-icon :icon="['fas', 'chevron-down']" />
      </div>
    </transition>
  </div>
</template>

<script>
// CONSTANTS
const SCROLLBAR_SCROLL_AMOUNT = 270;

import SidebarModule from './SidebarModule.vue';
import Placeholder from './SidebarModulesPlaceholder.vue';
import { mapState } from 'vuex';

export default {
  name: 'SidebarIndex',
  components: {
    SidebarModule,
    Placeholder,
  },
  props: {
    loadedFromLesson: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mounted: false,
      sidebarPosition: 0,
      sidebarScrollAmount: SCROLLBAR_SCROLL_AMOUNT,
    };
  },
  computed: {
    ...mapState('settings', {
      settings: (state) => state.settings,
    }),
    ...mapState('layout', {
      sidebarNest: (state) => state.sidebarNest,
      sidebarInner: (state) => state.sidebarInner,
      sidebarDownHidden: (state) => state.sidebarDownHidden,
    }),
    ...mapState('course', {
      course: (state) => state.course,
    }),
    ...mapState('lesson', {
      lesson: (state) => state.lesson,
    }),
    ...mapState('user', {
      guest: (state) => state.guest,
    }),
    loaded() {
      return !this.isMobile ? (this.isLessonView ? this.course && this.lesson : this.course) : this.course;
    },
    isRefs() {
      return this.$refs.sidebarNest && this.$refs.sidebarInner;
    },
    isLessonView() {
      return this.$route.name === 'lesson' || this.$route.name === 'share';
    },
    showSidebarUp() {
      return this.sidebarPosition > 0 && !this.isMobile;
    },
    showSidebarDown() {
      if (!this.isMobile && this.loaded && this.isRefs) {
        const sidebarBottom = this.$refs.sidebarNest.scrollHeight - this.$refs.sidebarNest.clientHeight;

        return this.sidebarPosition < sidebarBottom && !this.sidebarDownHidden;
      }
    },
  },
  watch: {
    settings: {
      handler(val) {
        if (val) {
          this.mounted = true;
          this.$nextTick(() => {
            this.$store.dispatch('layout/setSidebarNest', this.$refs.sidebarNest);
            this.$store.dispatch('layout/setSidebarInner', this.$refs.sidebarInner);
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleScroll(event) {
      this.sidebarPosition = event.target.scrollTop;
    },
    refreshSidebar() {
      this.$store.dispatch(
        'layout/setSidebarDownHidden',
        this.$refs.sidebarInner.clientHeight < this.$refs.sidebarNest.clientHeight,
      );
    },
    moveSidebar(amount) {
      this.sidebarNest.scroll({
        top: this.sidebarPosition + amount,
        behavior: 'smooth',
      });
    },
  },
  mounted() {
    if (!this.isMobile) {
      window.addEventListener('resize', this.refreshSidebar);
    }
    if (this.guest) {
      this.$store.dispatch('layout/setSidebarNest', this.$refs.sidebarNest);
      this.$store.dispatch('layout/setSidebarInner', this.$refs.sidebarInner);
    }
  },
  beforeUnmount() {
    if (!this.isMobile) {
      window.removeEventListener('resize', this.refreshSidebar);
    }
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: relative;
  height: 100%;

  &__nest {
    @include hide-scrollbar;

    @include phone-landscape(landscape) {
      max-width: 80%;
      margin: 0 auto;
    }
    @include tablet-portrait(portrait) {
      max-width: 80%;
      margin: 0 auto;
    }
    @include tablet-landscape(landscape) {
      max-width: 70%;
      margin: 0 auto;
    }
    @include tablet-landscape {
      max-width: 100%;
      padding: 14px;

      @supports (position: sticky) {
        height: 100%;
        overflow-y: scroll;
      }
    }
    @include desktop-xs {
      margin: 0 auto;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 14px;
    right: 14px;
    height: 45px;
    font-size: 21px;
    color: var(--gray-1);
    z-index: 10;
    cursor: pointer;

    &:hover {
      color: var(--white);
    }

    &--up {
      top: 0;
      background: linear-gradient(0deg, rgba(var(--black-rgb), 0) 0%, var(--black) 100%);
    }

    &--down {
      bottom: 0;
      background: linear-gradient(180deg, rgba(var(--black-rgb), 0) 0%, var(--black) 100%);
    }
  }

  &__placeholder {
    width: 100%;

    &--in-lesson {
      top: 14px;
      left: 14px;
      right: 14px;
      width: auto;
      min-height: 100%;
    }
  }
}
</style>
