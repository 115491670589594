<template>
  <div class="toast" :class="`toast--${notification.type}`" @click="goToLesson">
    <div class="toast__icon">
      <img :src="vaporAsset('images/icons/new-video.svg')" alt="icon" class="toast__icon-img" />
    </div>

    <div class="toast__body">
      <p class="toast__title">{{ title }}</p>
      <p class="toast__description">{{ notification.data.title }}</p>
    </div>

    <div class="toast__close" @click.stop="$emit('close-toast')"><span>&times;</span></div>
  </div>
</template>

<script>
import router from '@course-portal/routes';
import store from '@course-portal/store';
import i18n from '@shared/i18n';
import appLibrary from '@shared/base/appLibrary';

export default {
  name: 'NotificationNewLesson',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  mixins: [appLibrary],
  computed: {
    title() {
      return i18n.global.t('notifications.newVideoAdded');
    },
  },
  methods: {
    goToLesson() {
      router.push({ name: 'lesson', params: { id: this.notification.data.lesson_id } });
      this.dismissNotification();
    },
    dismissNotification() {
      store.dispatch('profile/readActivity', {
        id: this.notification.id,
        read: true,
      });
      this.$emit('close-toast');
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  cursor: pointer;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 50px;
    width: 50px;
    min-width: 50px;
    margin: 0 10px 0 0;

    @include tablet-portrait {
      height: 56px;
      width: 56px;
      min-width: 56px;
    }
  }

  &__description {
    font-weight: $semiBold;
  }
}
</style>
