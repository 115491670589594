<template>
  <div class="delete-account">
    <div class="delete-account__nest">
      <div class="delete-account__top">
        {{ $t('deleteAccount.deleteAccount') }}
        <a class="delete-account__close" @click="close">
          <font-awesome-icon :icon="['fas', 'xmark']" />
        </a>
      </div>

      <div class="delete-account__inner">
        <h3 class="delete-account__heading">{{ $t('deleteAccount.box1Title') }}</h3>
        <p class="delete-account__description">{{ $t('deleteAccount.box1Description') }}</p>

        <div class="delete-account__box">
          <h4 class="delete-account__box-title">{{ $t('deleteAccount.box2Title') }}</h4>
          <p class="delete-account__box-text">{{ $t('deleteAccount.box2Description') }}</p>
        </div>
        <div class="delete-account__box">
          <h4 class="delete-account__box-title">{{ $t('deleteAccount.box3Title') }}</h4>
          <p class="delete-account__box-text">{{ $t('deleteAccount.box3Description') }}</p>
        </div>

        <div class="delete-account__checkbox">
          <input type="checkbox" id="delete-account-confirm" v-model="confirmed" />
          <label for="delete-account-confirm">
            <span class="delete-account__checkbox-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"></path>
              </svg>
            </span>
            {{ $t('deleteAccount.accept') }}
          </label>
        </div>

        <app-button
          color="primary"
          large
          :class="{ 'button--disabled': !confirmed }"
          :disabled="!confirmed || deleting"
          @click="deleteUser">
          <span class="delete-account__button-loading" v-if="deleting"></span>
          <template v-else>
            {{ $t('deleteAccount.permanentlyDelete') }}
          </template>
        </app-button>

        <p class="delete-account__help">
          {{ $t('deleteAccount.questions') }}
          <a href="https://help.iphonephotographyschool.com/" target="_blank">{{ $t('deleteAccount.helpSection') }}</a
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      confirmed: false,
      deleting: false,
    };
  },
  methods: {
    close() {
      this.$store.dispatch('user/toggleDeleteAccount', false);
    },
    deleteUser() {
      if (!this.confirmed) return;

      this.deleting = true;
      this.$store.dispatch('user/deleteUser');
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-account {
  display: flex;
  position: fixed;
  top: $app-header-height;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(0);

  @include desktop-sm {
    justify-content: center;
    align-items: center;
    padding: 80px 0 20px 0;
    background: rgba(var(--gray-9-rgb), 0.9);
    backdrop-filter: blur(15px);
    opacity: 1;
    z-index: 200;

    @supports not (backdrop-filter: blur(15px)) {
      background: rgba(var(--gray-8-rgb), 0.95);
    }
  }

  &__nest {
    display: flex;
    flex-flow: column;
    position: relative;
    flex: 1;
    height: 100%;
    border-radius: 16px 16px 0 0;
    background: var(--gray-7);
    box-shadow: 2px 2px 28px 0px #000;
    overflow: hidden;

    @include desktop-sm {
      flex: none;
      max-height: 100%;
      width: 486px;
      max-width: 100%;
      border-radius: 12px;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 44px;
    font-size: $text-md;
    font-weight: $semiBold;
    text-align: center;
    border-bottom: 1px solid var(--gray-6);
  }

  &__inner {
    flex: 1;
    font-size: 15px;
    color: var(--gray-1);
    padding: 24px;
    overflow-y: auto;
  }

  &__heading {
    font-size: $text-lg;
    margin: 0 0 24px 0;
  }

  &__box {
    padding: 16px;
    margin: 24px 0;
    border-radius: 8px;
    border: 1px solid var(--gray-4);
  }

  &__box-title {
    color: var(--white);
    margin: 0 0 8px 0;
  }

  &__box-text {
    margin: 0;
  }

  &__checkbox {
    input {
      position: absolute;
      visibility: hidden;

      &:not(:checked) ~ label {
        .delete-account__checkbox-icon {
          border-color: var(--gray-4);
        }

        svg {
          opacity: 0;
        }
      }
    }

    label {
      display: flex;
      align-items: center;
      gap: 16px;
      position: relative;
    }
  }

  &__checkbox-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border: 1px solid var(--white);
    border-radius: 50%;
    transition: 0.3s ease;
    cursor: pointer;

    svg {
      width: 20px;
      transition: 0.2s ease-in-out;

      path {
        fill: var(--white);
      }
    }
  }

  &__help {
    margin: 0;

    a {
      text-decoration: underline;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    font-size: 24px;
    color: var(--white);
    padding: 7px 16px;
    border-radius: 20px;
  }

  &__button-loading {
    display: inline-block;
    vertical-align: middle;
    height: 28px;
    width: 28px;
    margin: 7px 0;
    border: 2px solid transparent;
    border-top-color: var(--white);
    border-radius: 50%;
    pointer-events: none;
    animation: loading 0.6s infinite linear;
  }

  .button {
    width: 100%;
    margin: 24px 0;

    &--disabled {
      color: var(--gray-3);
      pointer-events: none;
      filter: grayscale(1);
    }
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
