<template>
  <div class="video-yt">
    <div class="video-yt__nest">
      <iframe
        :src="`${lesson.video_url}?autoplay=1&rel=0`"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LessonVideoYoutube',
  computed: {
    ...mapState('lesson', {
      lesson: (state) => state.lesson,
    }),
  },
};
</script>

<style lang="scss">
.video-yt {
  position: relative;
  background-color: var(--black);
  background-size: cover;
  z-index: 100;

  @include tablet-landscape {
    border-radius: 3px;
    overflow: hidden;
  }
  @include ipad-pro-129(portrait) {
    border-radius: 0;
  }

  &__nest {
    position: relative;
    padding-bottom: 56.25%;

    @media only screen and (max-device-width: 428px) and (orientation: landscape) {
      padding-bottom: calc(100vh - #{$app-header-height});
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
</style>
