import appClient from '@shared/api/appClient';
import i18n from '@shared/i18n';

export default {
  namespaced: true,
  namespace: 'profile',
  state: {
    activity: [],
    activityLoaded: false,
    activityCurrentPage: null,
    activityLastPage: null,
    activityLoading: true,
    achievements: null,
    achievementHighlighted: null,
    currentBadge: null,
    nextBadge: null,
    activeTab: 0,
    tabs: [
      {
        id: 0,
        name: i18n.global.t('tabs.achievements'),
        component: 'ProfileAchievements',
      },
      {
        id: 1,
        name: i18n.global.t('tabs.activity'),
        component: 'ProfileActivity',
      },
    ],
  },
  actions: {
    getAchievements({ commit }) {
      return appClient.getAchievements().then(({ data }) => {
        if (data) {
          commit('GET_ACHIEVEMENTS', data);
        }
      });
    },
    setAchievementHighlighted({ commit }, payload) {
      commit('SET_ACHIEVEMENT_HIGHLIGHTED', payload);
    },
    getActivity({ state, commit }, page = 1) {
      state.activityLoading = true;

      return appClient.getActivity(page).then(({ data }) => {
        state.activityLoading = false;
        commit('GET_ACTIVITY', data);
      });
    },
    readActivity({ commit }, payload) {
      return appClient.readNotification(payload.id, payload.read).then(() => {
        commit('READ_ACTIVITY', payload.id);
        commit('notifications/REMOVE_NOTIFICATION', payload.id, { root: true });
      });
    },
    // Needs update to new endpoint once backend ready
    readAllActivities({ commit, dispatch }) {
      return new Promise((resolve) => {
        appClient.readAllNotifications().then(() => {
          commit('READ_ALL_ACTIVITIES');
          dispatch('notifications/resetIndicator', {}, { root: true });
          resolve();
        });
      });
    },
    selectTab({ commit }, value) {
      commit('SELECT_TAB', value);
    },
    resetIndicator({ commit }, value) {
      commit('RESET_INDICATOR', value);
    },
  },
  mutations: {
    GET_ACHIEVEMENTS(state, value) {
      state.achievements = {
        locked: value.locked_achievements,
        unlocked: value.unlocked_achievements,
      };
      state.currentBadge = value.current_badge;
      state.nextBadge = value.next_badge;
    },
    SET_ACHIEVEMENT_HIGHLIGHTED(state, payload) {
      state.achievementHighlighted = payload;
    },
    GET_ACTIVITY(state, value) {
      state.activity.push(...value.data);
      state.activityLoaded = true;
      state.activityCurrentPage = value.meta.current_page;
      state.activityLastPage = value.meta.last_page;
    },
    UPDATE_ACTIVITY(state, value) {
      state.activity.unshift(value);
    },
    READ_ACTIVITY(state, id) {
      const activity = state.activity.find((item) => item.id === id);
      activity.is_read = true;
    },
    READ_ALL_ACTIVITIES(state) {
      state.activity.forEach((activity) => {
        activity.is_read = true;
      });
    },
    SELECT_TAB(state, tab) {
      state.activeTab = tab;
    },
  },
};
