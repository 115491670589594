<template>
  <div class="avatar">
    <div class="avatar__img" :style="{ 'background-image': `url(${src})` }"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppAvatar',
  props: {
    src: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;
  border-radius: 50%;

  &__img {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: var(--black);
  }
}
</style>
