export default {
  namespaced: true,
  namespace: 'layout',
  state: {
    appHeight: null,
    headerHeight: null,
    headerTitle: null,
    tabsbarHeight: null,
    infoHeight: null,
    bannerHeight: null,
    sidebarNest: null,
    sidebarInner: null,
    sidebarDownHidden: false,
    isLandscape: null,
    showMenu: false,
    showContact: false,
    showAddToHomescreen: false,
    closeAddToHomescreenAnim: null,
    menuComponent: 'AppMenuList',
    menuTransition: 'slide-left',
    menuHeight: null,
    tabsElement: null,
    videoControlsVisible: false,
    cropping: false,
    notesHighlighted: false,
    appPromotion: false, // possible values : 'manual', 'auto', false
  },
  actions: {
    setAppHeight({ commit }, value) {
      commit('SET_APP_HEIGHT', value);
    },
    setHeaderHeight({ commit }, value) {
      commit('SET_HEADER_HEIGHT', value);
    },
    setHeaderTitle({ commit }, value) {
      commit('SET_HEADER_TITLE', value);
    },
    setTabsbarHeight({ commit }, value) {
      commit('SET_TABSBAR_HEIGHT', value);
    },
    setInfoHeight({ commit }, value) {
      commit('SET_INFO_HEIGHT', value);
    },
    setBannerHeight({ commit }, value) {
      commit('SET_BANNER_HEIGHT', value);
    },
    setSidebarNest({ commit }, value) {
      commit('SET_SIDEBAR_NEST', value);
    },
    setSidebarInner({ commit }, value) {
      commit('SET_SIDEBAR_INNER', value);
    },
    setSidebarDownHidden({ commit }, value) {
      commit('SET_SIDEBAR_DOWN_HIDDEN', value);
    },
    setLandscape({ commit }, value) {
      commit('SET_LANDSCAPE', value);
    },
    toggleMenu({ commit }, payload) {
      commit('TOGGLE_MENU', payload);
    },
    setMenuComponent({ commit }, payload) {
      commit('SET_MENU_COMPONENT', payload);
    },
    setMenuHeight({ commit }, payload) {
      commit('SET_MENU_HEIGHT', payload);
    },
    setTabsElement({ commit }, value) {
      commit('SET_TABS_ELEMENT', value);
    },
    toggleContact({ commit }, payload) {
      commit('TOGGLE_CONTACT', payload);
    },
    toggleAddToHomescreen({ commit }, payload) {
      commit('TOGGLE_ADD_TO_HOMESCREEN', payload);
    },
    toggleVideoControlsVisible({ commit }, value) {
      commit('TOGGLE_VIDEO_CONTROLS_VISIBLE', value);
    },
    toggleCropping({ commit }, value) {
      commit('TOGGLE_CROPPING', value);
    },
    setNotesHighlighted({ commit }, value) {
      commit('SET_NOTES_HIGHLIGHTED', value);
    },
    toggleAppPromotion({ state, commit, dispatch, rootState }, value) {
      if (!value && rootState.quickTips.initialAutoPromotionShown) {
        setTimeout(() => {
          dispatch('quickTips/toggleShowSwipeIndicator', true, { root: true });
        }, 4000);
      }
      if (
        (rootState.user.guest && rootState.popup.popup?.type === 'upsell' && value === 'auto') ||
        rootState.user.user?.has_cam_guru
      )
        return;

      commit('TOGGLE_APP_PROMOTION', value);
    },
  },
  mutations: {
    SET_APP_HEIGHT(state, value) {
      state.appHeight = value;
    },
    SET_HEADER_HEIGHT(state, value) {
      state.headerHeight = value;
    },
    SET_HEADER_TITLE(state, value) {
      state.headerTitle = value;
    },
    SET_TABSBAR_HEIGHT(state, value) {
      state.tabsbarHeight = value;
    },
    SET_INFO_HEIGHT(state, value) {
      state.infoHeight = value;
    },
    SET_BANNER_HEIGHT(state, value) {
      state.bannerHeight = value;
    },
    SET_SIDEBAR_NEST(state, value) {
      state.sidebarNest = value;
    },
    SET_SIDEBAR_INNER(state, value) {
      state.sidebarInner = value;
    },
    SET_SIDEBAR_DOWN_HIDDEN(state, value) {
      state.sidebarDownHidden = value;
    },
    SET_LANDSCAPE(state, value) {
      state.isLandscape = value;
    },
    TOGGLE_MENU(state, value) {
      state.showMenu = value;
    },
    SET_MENU_COMPONENT(state, value) {
      state.menuComponent = value.component;
      state.menuTransition = value.transition;
    },
    SET_MENU_HEIGHT(state, value) {
      state.menuHeight = value + 2;
    },
    SET_TABS_ELEMENT(state, value) {
      state.tabsElement = value;
    },
    TOGGLE_CONTACT(state, value) {
      state.showContact = value;
    },
    TOGGLE_ADD_TO_HOMESCREEN(state, value) {
      state.showAddToHomescreen = value.show;
      state.closeAddToHomescreenAnim = value.anim;
    },
    TOGGLE_VIDEO_CONTROLS_VISIBLE(state, value) {
      state.videoControlsVisible = value;
    },
    TOGGLE_CROPPING(state, value) {
      state.cropping = value;
    },
    SET_NOTES_HIGHLIGHTED(state, value) {
      state.notesHighlighted = value;
    },
    TOGGLE_APP_PROMOTION(state, value) {
      state.appPromotion = value;
    },
  },
};
