<template>
  <div class="toast" :class="`toast--${notification.type}`" @click="goToLesson">
    <app-avatar class="toast__img" :src="avatar"></app-avatar>

    <div class="toast__body">
      <p class="toast__description">
        <strong>{{ commenter.first_name }} {{ commenter.last_name }}</strong>
        {{ repliedTo }} {{ notification.data.course.title }} {{ video }} "{{ notification.data.lesson.title }}"
      </p>
    </div>

    <div class="toast__close" @click.stop="$emit('close-toast')"><span>&times;</span></div>
  </div>
</template>

<script>
import AppAvatar from '@course-portal/components/ui/AppAvatar.vue';
import router from '@course-portal/routes';
import store from '@course-portal/store';
import i18n from '@shared/i18n';
import appLibrary from '@shared/base/appLibrary';

export default {
  name: 'NotificationCommentReply',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  mixins: [appLibrary],
  components: {
    AppAvatar,
  },
  computed: {
    commenter() {
      return this.notification.data.comment.user;
    },
    avatar() {
      return this.commenter.avatar || this.vaporAsset('images/avatar-placeholder.png');
    },
    repliedTo() {
      return i18n.global.t('notifications.repliedToYourComment');
    },
    video() {
      return i18n.global.t('notifications.video');
    },
  },
  methods: {
    goToLesson() {
      router.push({
        name: 'lesson',
        params: {
          id: this.notification.data.lesson.id,
        },
        query: {
          comment_id: this.notification.data.comment.id,
        },
      });
      this.dismissNotification();
    },
    dismissNotification() {
      store.dispatch('profile/readActivity', {
        id: this.notification.id,
        read: true,
      });
      this.$emit('close-toast');
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  cursor: pointer;

  &__img {
    height: 52px;
    width: 52px;
    margin: 0 10px 0 0;
  }
}
</style>
