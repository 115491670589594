<template>
  <div class="profile-public">
    <app-container :small="true">
      <div class="profile-public__nest" v-if="user">
        <div class="profile-public__top">
          <a class="profile-public__back-btn" @click.prevent="goBack" v-if="from">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
          </a>

          <div
            class="profile-public__avatar"
            :style="{
              'background-image': `url(${user.avatar ? user.avatar : vaporAsset('images/avatar-placeholder.png')})`,
            }"></div>

          <div class="profile-public__name">{{ `${user.first_name} ${user.last_name}` }}</div>

          <div class="profile-public__badge" :class="`profile-public__badge--${user.badge}`">{{ user.badge }}</div>
        </div>

        <div class="profile-public__achievements" v-if="isAchievements">
          <h3 class="profile-public__achievements-title">
            {{ $t('achievements.earnedAchievements') }}
            <span class="profile-public__achievements-earned">
              <span class="profile-public__achievements-star">&#9733;</span>
              {{ user.achievements.length }}
            </span>
          </h3>

          <div class="profile-public__achievements-grid">
            <achievements-item
              v-for="achievement in user.achievements"
              :achievement="achievement"
              :key="achievement.id"></achievements-item>
          </div>
        </div>
      </div>
      <profile-public-placeholder v-else></profile-public-placeholder>
    </app-container>
  </div>
</template>

<script>
import ProfilePublicPlaceholder from './ProfilePublicPlaceholder.vue';
import AchievementsItem from './ProfileAchievementsItem.vue';
import AppContainer from '@course-portal/components/layout/AppContainer.vue';
import appClient from '@shared/api/appClient';

export default {
  name: 'ProfilePublic',
  components: {
    ProfilePublicPlaceholder,
    AchievementsItem,
    AppContainer,
  },
  data() {
    return {
      user: null,
      from: null,
    };
  },
  computed: {
    isAchievements() {
      return this.user.achievements && this.user.achievements.length;
    },
  },
  methods: {
    goBack() {
      if (this.from) {
        this.$router.back();
      } else {
        this.$router.push({ name: 'home' });
      }
    },
  },
  mounted() {
    appClient.getPublicProfile(this.$route.params.id).then(({ data }) => {
      this.user = data;
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from.name;
    });
  },
};
</script>

<style lang="scss" scoped>
.profile-public {
  padding: 20px 0 50px 0;

  @include tablet-landscape {
    padding: 20px 0 40px 0;
  }
  @include tablet-portrait {
    padding: 60px 0;
  }
  @include iphone-11(landscape) {
    padding: 30px 0;
  }
  @include iphone-11-pro(landscape) {
    padding: 30px 0;
  }
  @include iphone-11-pro-max(landscape) {
    padding: 30px 0;
  }
  @include iphone-12(landscape) {
    padding: 30px 0;
  }
  @include iphone-12-pro-max(landscape) {
    padding: 30px 0;
  }

  &__top {
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    margin: 0 0 50px 0;
  }

  &__back-btn {
    position: absolute;
    top: 0;
    left: 0;
    font-size: $header-sm;
  }

  &__avatar {
    position: relative;
    height: 100px;
    width: 100px;
    margin-bottom: 13px;
    border-radius: 50%;
    background-color: var(--gray-6);
    background-size: cover;

    @include tablet-portrait {
      height: 142px;
      width: 142px;
    }
    @include iphone-11(landscape) {
      height: 100px;
      width: 100px;
    }
    @include iphone-11-pro(landscape) {
      height: 100px;
      width: 100px;
    }
    @include iphone-11-pro-max(landscape) {
      height: 100px;
      width: 100px;
    }
    @include iphone-12(landscape) {
      height: 100px;
      width: 100px;
    }
    @include iphone-12-pro-max(landscape) {
      height: 100px;
      width: 100px;
    }
  }

  &__name {
    font-size: $header-md;
    font-weight: $semiBold;
  }
  &__badge {
    line-height: 28px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    padding: 0 12px;
    margin-top: 7px;
    border-radius: 4px;
    background-color: var(--beginner);

    &--Intermediate {
      background-color: var(--intermediate);
    }
    &--Advanced {
      background-color: var(--advanced);
    }
    &--Master {
      background-color: var(--master);
    }
    &--Staff {
      background-color: var(--staff);
    }
  }

  &__achievements {
    padding: 20px 0;
    border-radius: 8px;
    background: var(--gray-8);
  }

  &__achievements-grid {
    display: flex;
    flex-flow: row wrap;
  }

  &__achievements-title {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-size: normal;
    text-align: left;
    padding: 0 20px;
    margin: 0 0 30px 0;
  }

  &__achievements-earned {
    font-size: 16px;
    font-weight: normal;
    color: var(--white);
    margin-left: auto;
  }

  &__achievements-star {
    color: var(--yellow);
    font-size: 18px;
  }
}
</style>
