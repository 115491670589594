import axios from 'axios';
import store from '@course-portal/store';
import router from '@course-portal/routes';
import routerShared from '@shared-lesson/routes';
import { isMobile, isAppMode } from '@shared/base/appFlags';

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = (baseUrl = null) => {
  const options = {
    baseURL: baseUrl,
    headers: {
      'x-lang': window.App.language ?? 'en',
      Accept: 'application/json, text/plain, */*',
      'X-Requested-With': isAppMode() ? 'pwa' : 'web',
    },
  };

  const client = axios.create(options);

  // Global response errors catch
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // Conditionally disable global interceptors
      if (error.config.noInterceptors) {
        return Promise.reject(error);
      }

      if (error.response && error.response.status) {
        switch (error.response.status) {
          case 403:
            router.push({ name: 'home' });

            store.dispatch(
              'notifications/showToast',
              {
                item: {
                  type: 'warning',
                  content: {
                    message: `You don't have access to that course so we have redirected you to your courses.`,
                  },
                },
                isMobile: isMobile(),
              },
              { root: true },
            );
            break;

          case 404 && !error.response.config.url.includes('login-with-email/send'):
            // Show 404 page
            store.state.user.guest ? routerShared.push('/404') : router.push('/404');
            break;

          case 419:
            // Refresh our session token
            await axios.get('/sanctum/csrf-cookie');

            // Return a new request using the original request's configuration
            return axios(error.response.config);

          default:
            const isHome = router.currentRoute._value.name === 'home';

            store.dispatch(
              'notifications/showToast',
              {
                item: {
                  type: 'error',
                  content: {
                    message: `Something went wrong. Please try refreshing the page${isHome ? '' : ' or <span>click here to go to the courses page</span>'}.`,
                  },
                },
                isMobile: isMobile(),
                clickEvent(closeToast) {
                  if (isHome) {
                    return;
                  }

                  router.push({ name: 'home' });
                  closeToast();
                },
              },
              { root: true },
            );
        }
      }

      return Promise.reject(error);
    },
  );

  return client;
};

class ApiClient {
  constructor(baseUrl = null) {
    this.client = getClient(baseUrl);
  }
  get(url, conf = {}) {
    return this.client
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }
  post(url, data = {}, conf = {}) {
    return this.client
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }
  put(url, data = {}, conf = {}) {
    return this.client
      .put(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }
  patch(url, data = {}, conf = {}) {
    return this.client
      .patch(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }
  delete(url, data = {}, conf = {}) {
    return this.client
      .delete(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }
}
export { ApiClient };

/**
 * Base HTTP Client
 */
export default {
  // Provide request methods with the default base_url
  get(url, conf = {}) {
    return getClient()
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  post(url, data = {}, conf = {}) {
    return getClient()
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  patch(url, data = {}, conf = {}) {
    return getClient()
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  delete(url, data = {}, conf = {}) {
    return getClient()
      .delete(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};
