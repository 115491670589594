<template>
  <div class="home-card-sale" @click="openLockedLink">
    <div class="home-card-sale__timer">
      <div class="home-card-sale__timer-nest">
        <app-timer :end="salesConfig?.course_card?.timer" />
      </div>
    </div>
    <div class="home-card-sale__nest">
      <img
        :src="`https://images.iphonephotographyschool.com/${isMobile ? courseOnSale?.course.cover_square : courseOnSale?.course.cover_wide}/${this.coverWideSize}/ipa.jpg`"
        alt=""
        class="home-card-sale__cover" />
      <div class="home-card-sale__info">
        <h3 class="home-card-sale__title">{{ courseOnSale?.course.title }}</h3>
        <div class="home-card-sale__bullets">
          <div class="home-card-sale__bullet" v-for="bullet in courseOnSale?.course.bullets" :key="bullet.position">
            <font-awesome-icon :icon="['fas', 'check']" class="home-card-sale__bullet-icon" />
            {{ bullet.text }}
          </div>
        </div>
        <app-button color="orange" class="home-card-sale__button">
          <i18n-t keypath="promotion.getPercentDiscount">
            <template v-slot:percent>
              <span>{{ salesConfig?.course_card?.percentage }}</span>
            </template>
          </i18n-t>
          <font-awesome-icon :icon="['fas', 'chevrons-right']" />
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
// CONSTANTS
const wideSize = 940;

import NProgress from 'nprogress';
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      urlReady: false,
      openIosLockedLink: false,
    };
  },
  computed: {
    ...mapGetters('courses', ['courseOnSale']),
    ...mapState('pwa', {
      isIosUrlParams: (state) => state.isIosUrlParams,
    }),
    salesConfig() {
      return this.courseOnSale?.salesConfig;
    },
    coverWideSize() {
      return Math.ceil(this.pixelRatio * wideSize);
    },
    lockedLink() {
      return this.handleSalesPageLinkParams(this.salesConfig?.course_card.url, this.salesConfig?.ios_locked_params);
    },
  },
  watch: {
    isIosUrlParams: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.urlReady = true;

        if (!this.openIosLockedLink) return;
        window.open(this.lockedLink, '_blank');
      },
    },
  },
  methods: {
    openLockedLink() {
      this.trackEvent('button_clicked', { button: 'courses_view_find_out_more' });

      if (this.isAppMode) {
        NProgress.start();
        NProgress.set(0.4);

        if (this.urlReady) {
          window.open(this.lockedLink, '_blank');
          return;
        }

        this.openIosLockedLink = true;
        return;
      }

      window.open(this.lockedLink, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.home-card-sale {
  position: relative;
  padding: 1px;
  margin-top: 42px;
  border-radius: 12px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 12px;
    background: var(--organe-linear, linear-gradient(130deg, var(--promo-start) 0.45%, var(--promo-end) 100%));
  }

  &__timer {
    position: absolute;
    top: 0;
    left: 50%;
    padding: 1px;
    border-radius: 12px;
    transform: translate(-50%, -50%);
    z-index: 3;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 12px;
      background: var(--organe-linear, linear-gradient(130deg, var(--promo-start) 0.45%, var(--promo-end) 100%));
    }
  }

  &__timer-nest {
    position: relative;
    border-radius: 12px;
    overflow: hidden;

    .timer {
      position: relative;
    }
  }

  &__nest {
    position: relative;
    padding: 0 0 100% 0;

    @include phone-landscape {
      height: 452px;
      padding: 0;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 12px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      z-index: 1;
    }
  }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    background: var(--gray-8);
  }

  &__info {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    gap: 18px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 18px;
    z-index: 2;

    @include tablet-landscape {
      gap: 24px;
      padding: 24px;
    }
  }

  &__title {
    font-size: $header-sm;
    line-height: 1;
    font-weight: $bold;
    margin: 0;

    @include tablet-landscape {
      font-size: $header-md;
      gap: 24px;
    }
  }

  &__bullets {
    display: flex;
    flex-flow: column;
    gap: 8px;
    font-size: $text-sm;

    @include tablet-landscape {
      gap: 12px;
      font-size: $text-lg;
    }
  }

  &__bullet {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__bullet-icon {
    color: var(--promo-start);
  }

  &__button {
    text-transform: capitalize;
    padding: 0 24px;
  }
}
</style>
