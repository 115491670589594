<template>
  <div class="container" :class="{ 'container--fluid': fluid, 'container--xs': small, 'container--md': medium }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppContainer',
  props: {
    fluid: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    medium: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: $container-width;
  margin-right: auto;
  margin-left: auto;
  padding: 0 10px;

  @include tablet-portrait {
    padding: 0 20px;
  }

  &--xs {
    width: $container-xs-width;
    max-width: 100%;
  }

  &--md {
    width: $container-md-width;
    max-width: 100%;
  }

  &--fluid {
    width: $container-max-width;
    max-width: 100%;
    padding: 0;

    @include tablet-portrait {
      padding: 0;
    }
  }
}
</style>
