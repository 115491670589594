<template>
  <div
    class="video-popup"
    :class="{
      'video-popup--fullscreen': isFullscreen(),
      'video-popup--floating': floating,
      'video-popup--ipad': isIPad,
    }"
    ref="videoPopup"
    v-if="nextLesson || nextVideoLoading">
    <div class="video-popup__loading" v-if="nextVideoLoading"></div>

    <div class="video-popup__button-back" @click="goBack" v-if="isPhone && !nextVideoLoading">
      <font-awesome-icon :icon="['fas', 'chevron-left']" />
    </div>

    <div class="video-popup__item" ref="nextVideo" v-if="!nextVideoLoading">
      <div class="video-popup__next-lesson">
        <div class="video-popup__thumbnail" :style="{ 'background-image': `url(${nextLesson.thumbnail})` }"></div>
        <div class="video-popup__text">
          <div class="video-popup__subtitle" v-if="settings.autoplay && !floating">
            {{ $t('lesson.nextLessonIn') }}... {{ countdownSeconds }}
          </div>
          <div class="video-popup__subtitle" v-else>{{ $t('lesson.nextLesson') }}...</div>
          <div class="video-popup__title">{{ nextLesson.title }}</div>
        </div>
      </div>

      <div class="video-popup__buttons">
        <app-button color="gray-5" @click="close">{{ $t('cancel') }}</app-button>
        <app-button
          class="video-popup__button-next"
          color="white"
          @click="openNextLesson()"
          @mouseenter="!floating && $store.dispatch('lesson/pauseCountdown', true)"
          @mouseleave="!floating && $store.dispatch('lesson/pauseCountdown', false)">
          <div
            class="video-popup__button-progress"
            :style="{ width: `${countdownProgress}%` }"
            v-if="settings.autoplay && !floating"></div>
          <span>
            <font-awesome-icon :icon="['fas', 'play']" class="video-popup__icon" />
            {{ $t('watchNow') }}
          </span>
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'LessonVideoPopup',
  props: {
    floating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('course', ['courseId']),
    ...mapGetters('lesson', ['singleLesson']),
    ...mapState('settings', {
      settings: (state) => state.settings,
    }),
    ...mapState('layout', {
      headerHeight: (state) => state.headerHeight,
    }),
    ...mapState('course', {
      course: (state) => state.course,
    }),
    ...mapState('lesson', {
      lesson: (state) => state.lesson,
      module: (state) => state.module,
      countdown: (state) => state.countdown,
      nextVideoLoading: (state) => state.nextVideoLoading,
    }),
    nextLesson() {
      return this.singleLesson(this.lesson.next_lesson_id).lesson;
    },
    countdownSeconds() {
      return this.countdown.seconds > 10 ? Math.round(this.countdown.seconds / 10) : 1;
    },
    countdownProgress() {
      return ((this.countdown.from - this.countdown.seconds) / this.countdown.from) * 100;
    },
  },
  watch: {
    'countdown.seconds': {
      handler(val) {
        if (!val) {
          setTimeout(() => {
            this.openNextLesson(true);
          }, 1000);
        }
      },
    },
    'countdown.running': {
      handler(val) {
        if (val) {
          this.$store.dispatch('lesson/resetCountdown');
        }
      },
    },
  },
  methods: {
    close() {
      this.fireTrackerEvent('button_clicked', 'up_next_video_cancel');
      this.$store.dispatch('lesson/setShowUpcoming', false);
      this.$store.dispatch('lesson/toggleCountdown', false);

      if (this.isFullscreen()) {
        this.closeFullscreen();
      }
    },
    openNextLesson(auto) {
      if (this.lesson) {
        this.fireTrackerEvent(
          auto ? 'up_next_video_watch_now_auto_clicked' : 'button_clicked',
          auto ? null : 'up_next_video_watch_now_clicked',
        );
        this.$store.dispatch('lesson/toggleNextVideoLoading', true);
        this.$router.push({ name: 'lesson', params: { id: this.lesson.next_lesson_id, autoplay: true } });
      }
    },
    goBack() {
      this.$router.push({ name: 'course', params: { id: this.courseId } });
    },
    checkPopupVisible() {
      const popup = this.$refs.nextVideo;
      const inViewport = popup ? this.elementInViewport(popup) : true;

      if (!inViewport) {
        this.$store.dispatch('lesson/toggleCountdown', false);
        return false;
      } else if (inViewport && !this.countdown.paused && !this.floating) {
        this.$store.dispatch('lesson/toggleCountdown', true);
        return true;
      }
    },
    fireTrackerEvent(action, name) {
      this.trackEvent(action, {
        ...(name && { button: name }),
        course_id: this.course.id,
        course_slug: this.course.product_slug,
        course_name: this.course.title,
        module_id: this.module.id,
        module_name: this.module.title,
        up_next_lesson_name: this.lesson.name,
        up_next_lesson_id: this.lesson.next_lesson_id,
      });
    },
  },
  mounted() {
    if (this.isPhone) {
      this.$nextTick(() => this.$refs['videoPopup']?.scrollIntoView({ block: 'nearest' }));
    }
    if (this.settings.autoplay) {
      window.addEventListener('scroll', this.checkPopupVisible);
      if (!this.floating) {
        this.$store.dispatch('lesson/startCountdown');
      }
      if (!this.checkPopupVisible()) {
        this.$store.dispatch('lesson/toggleCountdown', false);
      }
    }
  },
  beforeUnmount() {
    if (this.settings.autoplay) {
      window.removeEventListener('scroll', this.checkPopupVisible);
    }
  },
};
</script>

<style lang="scss" scoped>
.video-popup {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 10px 10px 10px;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
  z-index: 10;

  @include tablet-portrait {
    padding: 0 16px 16px 16px;
  }
  @include tablet-landscape {
    border-radius: 4px;
  }

  &--floating {
    align-items: center;
    padding: 0;

    .video-popup__item {
      display: flex;
      flex-flow: column;
      justify-content: center;
      height: 100%;
    }
  }

  &--fullscreen {
    padding: 0 64px 64px 64px;
  }

  &--ipad.video-popup--fullscreen {
    padding: 0 16px 64px 16px;
  }

  &__item {
    width: 100%;
    width: 460px;
    padding: 0;
    background: transparent;
    border-radius: 8px;

    @include phone-landscape {
      background: var(--gray-8);
      padding: 16px 16px;
    }

    @include desktop-sm {
      width: 460px;
      background: var(--gray-8);
      padding: 16px 16px;
    }
  }

  &__next-lesson {
    display: flex;
    align-items: center;
  }

  &__text {
    font-size: $text-md;

    @include desktop-sm {
      font-size: $text-lg;
    }
  }

  &__subtitle {
    color: var(--gray-3);
    font-size: $text-md;
  }

  &__thumbnail {
    position: relative;
    height: 70px;
    min-width: 122px;
    margin: 0 18px 0 0;
    border-radius: 5px;
    background-size: cover;
    background-position: 50% 50%;

    @include desktop-sm {
      height: 80px;
      min-width: 140px;
      margin: 0 20px 0 0;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin: 17px 0 0 0;

    @include desktop-sm {
      margin: 25px 0 0 0;
    }

    .button {
      width: calc(50% - 7px);
      font-size: $text-md;
      font-weight: 500;

      @include desktop-sm {
        font-size: $text-lg;
        width: calc(50% - 10px);
      }

      &:first-child {
        font-weight: 400;
        color: var(--gray-1);
      }
    }
  }

  &__button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &:hover {
      .video-popup__button-progress {
        background: var(--gray-1);
      }
    }

    span {
      position: relative;
    }
  }

  &__button-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--gray-1);
    transition: width 0.1s linear;
  }

  &__icon {
    font-size: 16px;
    margin-right: 8px;
  }

  &__button-back {
    box-sizing: border-box !important;
    position: absolute;
    top: 3px;
    left: 0;
    height: 44px;
    font-size: 20px;
    line-height: 44px;
    text-align: center;
    color: #fff;
    padding: 0 10px;
    z-index: 1;
    cursor: pointer;

    svg {
      display: block;
      height: 100%;
      margin: 0 auto;
    }
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 64px;
    width: 64px;
    margin: -32px 0 0 -32px;
    border: 4px solid transparent;
    border-top-color: var(--white);
    border-radius: 50%;
    pointer-events: none;
    animation: loading 0.6s infinite ease-in-out;
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
