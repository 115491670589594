<template>
  <div class="comments-ph">
    <div class="comments-ph__commenter">
      <div class="comments-ph__item comments-ph__comment-box"></div>
    </div>

    <div class="comments-ph__comment" v-for="(comment, index) in comments" :key="index">
      <div class="comments-ph__item comments-ph__comment-main" :class="`comments-ph__comment-main--${index}`"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LessonCommentsPlaceholder',
  props: {
    comments: {
      type: Number,
      required: false,
      default: 3,
    },
  },
};
</script>

<style lang="scss" scoped>
.comments-ph {
  &__item {
    position: relative;
    border-radius: 8px;
    background: var(--gray-8);
    animation: shimmer 1s ease-in-out infinite;
    animation-direction: alternate;
  }

  &__comment {
    display: flex;
    flex-basis: 100%;
    margin: 0 0 67px 0;

    &:last-child {
      margin: 0;
    }
  }

  &__commenter {
    display: flex;
    position: relative;
    flex-basis: 100%;
    position: relative;
    padding: 0 0 0 24px;
    margin: 0 0 32px 0;

    @include tablet-portrait {
      margin: 0 0 40px 0;
    }
  }

  &__comment-main {
    width: 100%;
    max-width: 100%;
    height: 100px;

    @include phone-landscape {
      &--0 {
        width: 50%;
      }

      &--1 {
        width: 80%;
      }

      &--2 {
        width: 60%;
      }

      &--4 {
        width: 60%;
      }

      &--5 {
        width: 50%;
      }
    }
  }

  &__comment {
    position: relative;
    padding-left: 24px;
  }

  &__comment-box {
    width: 100%;
    height: 54px;

    @include tablet-portrait {
      height: 64px;
    }
  }

  &__comment-avatar {
    position: absolute;
    top: 6px;
    left: 0;
    height: 42px;
    min-width: 42px;
    border-radius: 50%;
    background: var(--black);
    z-index: 2;
    animation: none;

    @include tablet-portrait {
      top: 8px;
      height: 48px;
      min-width: 48px;
    }
  }
}
</style>
