<template>
  <div class="upsell-banner" v-if="showUpsellBanner && course">
    <span class="upsell-banner__text">
      {{ $t('lesson.ourBestsellingCours') }} <span v-html="course.title" /> {{ $t('lesson.course') }}
      {{ $t('lesson.discoverMore') }}
    </span>
    <a :href="handleSalesPageLinkParams(course.locked_url)" target="_blank" class="upsell-banner__btn">
      <span v-html="'Get ' + course.title" />
    </a>
    <div class="upsell-banner__close" @click="showUpsellBanner = false">&times;</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LessonUpsellBanner',
  data() {
    return {
      showUpsellBanner: true,
    };
  },
  computed: {
    ...mapState('course', {
      course: (state) => {
        if (!state.course) return;

        const course = state.course;

        if (course.product_slug === 'iphone-camera-academy') {
          course.title = 'iPhone&nbsp;Photo&nbsp;Academy';
        }

        return course;
      },
    }),
  },
};
</script>

<style lang="scss">
.upsell-banner {
  position: relative;
  background: var(--gray-6);
  padding: 24px 20px;
  margin: 11px;
  border-radius: 12px;

  @include tablet-landscape {
    margin: 14px 0;
  }

  &__text {
    font-size: 13px;
    display: block;
    padding-right: 20px;
    color: var(--banner-text);

    @include tablet-landscape {
      font-size: 14px;
      padding-right: 69px;
    }

    span {
      color: var(--white);
      font-weight: 400;
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    font-size: 21px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    background-color: rgba(var(--black-rgb), 0.2);
    border-radius: 50%;

    @include tablet-landscape {
      width: 34px;
      height: 34px;
      top: 16px;
      right: 16px;
    }
  }

  &__btn {
    display: block;
    position: relative;
    margin-top: 28px;
    width: 100%;
    max-width: 400px;
    font-size: 16px;
    line-height: 44px;
    text-align: center;
    text-decoration: none;
    color: var(--white);
    border-radius: 6px;
    background: linear-gradient(165deg, #d41c3d 0%, #861b83 100%);
    transition: all 0.2s ease-in-out;

    &:hover {
      background: linear-gradient(182deg, #d41c3d 0%, #861b83 100%);
    }

    span {
      position: relative;
    }
  }
}
</style>
