import { createStore } from 'vuex';
import settings from './modules/settings';
import user from './modules/user';
import profile from './modules/profile';
import banner from './modules/banner';
import layout from './modules/layout';
import popup from './modules/popup';
import notifications from './modules/notifications';
import timer from './modules/timer';
import courses from './modules/courses';
import course from './modules/course';
import lesson from './modules/lesson';
import comments from './modules/comments';
import refer from './modules/refer';
import quickTips from './modules/quickTips';
import pwa from './modules/pwa';
import photoReview from './modules/photoReview';

const store = createStore({
  modules: {
    settings,
    user,
    profile,
    banner,
    layout,
    popup,
    notifications,
    timer,
    courses,
    course,
    lesson,
    comments,
    refer,
    quickTips,
    pwa,
    photoReview,
  },
});

export default store;
