<template>
  <div class="toast" :class="`toast--${notification.type}`">
    <div class="toast__icon">
      <i class="fas fa-check"></i>
    </div>

    <div class="toast__body">
      <p class="toast__title" v-html="notification.data.title" v-if="notification.data.title"></p>
      <p class="toast__description" v-html="notification.data.content" v-if="notification.data.content"></p>
    </div>

    <div class="toast__close" @click.stop="$emit('close-toast')"><span>&times;</span></div>
  </div>
</template>

<script>
export default {
  name: 'NotificationSuccess',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  &__icon {
    position: relative;
    height: 50px;
    width: 50px;
    min-width: 50px;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    margin: 0 10px 0 0;
    border: 2px solid var(--green-light);
    border-radius: 50%;
    overflow: hidden;

    @include tablet-portrait {
      height: 56px;
      width: 56px;
      min-width: 56px;
      line-height: 46px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      background: var(--gray-5);
    }
  }

  &__description {
    font-weight: $semiBold;

    ::v-deep(span) {
      text-decoration: underline;
    }
  }

  svg {
    vertical-align: middle;
    position: relative;
    color: var(--green-light);
  }
}
</style>
