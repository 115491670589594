// isoview.js

export const iosview = {
  productsRequest(idList) {
    window.webkit.messageHandlers['iap-products-request'].postMessage(idList);
    // response listeners => iap-products-result, iap-products-result-failure
  },
  restorePurchases() {
    window.webkit.messageHandlers['iap-restore-purchases-request'].postMessage('');
  },
  restorePurchasesFinalized() {
    window.webkit.messageHandlers['iap-restore-purchases-finalized'].postMessage('');
  },
  initiateAllowNotificationsPopup() {
    window.webkit.messageHandlers['allow-notifications-popup'].postMessage('');
  },
  deleteStoredProducts() {
    window.webkit.messageHandlers['iap-delete-stored-products'].postMessage('');
  },
  setNotificationsBadge(count) {
    window.webkit.messageHandlers['update-notifications-badge-count'].postMessage(count);
  },
};
