import { createWebHistory, createRouter } from 'vue-router';
import Lesson from '@course-portal/pages/lesson/index.vue';
import PageNotFound from '@course-portal/components/layout/AppPageNotFound.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/share/lesson/:id',
      name: 'share',
      component: Lesson,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'page-not-found',
      component: PageNotFound,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

export default router;
