<template>
  <div class="files">
    <lesson-files-item v-for="file in files" :file="file" :key="file.id"></lesson-files-item>
  </div>
</template>

<script>
import LessonFilesItem from './LessonFilesItem.vue';
import { mapState } from 'vuex';

export default {
  name: 'LessonFiles',
  components: {
    LessonFilesItem,
  },
  computed: {
    ...mapState('lesson', {
      files: (state) => state.lesson.files,
    }),
  },
};
</script>

<style lang="scss" scoped>
.files {
  width: 100%;
  padding: 0 0 60px 0;

  &__heading {
    font-size: 19px;
    font-weight: $semiBold;
    margin: 0 0 30px 0;
  }
}
</style>
